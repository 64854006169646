import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Pagination} from '../models/pagination';
import {Transaction} from '../models/transaction';
import {Response} from '../models/response';
import {Constant} from '../constants/constant';
import {DateService} from '../utils/date.service';
import {EncryptService} from '../utils/encrypt.service';
import {CustomService} from './custom.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: HttpService) {
  }

  static toPageTransaction(res): Pagination<Transaction> {
    let page: Pagination<Transaction> = new Pagination<Transaction>(res);

    let transactions: Array<Transaction> = [];
    for (let trxs of res['TrxsInfo']) {
      let trans: Transaction = new Transaction();
      trans.amount = trxs['Amount'];
      trans.traceNum = trxs['TraceNum'];
      trans.cardNum = trxs['CardNum'];
      trans.cardType = trxs['CardType'];
      trans.tenderType = trxs['TenderType'];
      trans.result = trxs['Result'];
      trans.tipAmt = trxs['Tip'];
      trans.transType = trxs['TransType'];
      trans.user = trxs['User'];
      trans.result = trxs['Result'];
      trans.timeZone = trxs['TimeZone'];
      trans.userDeleted = trxs['UserDeleted'];
      trans.returnFlag = trxs['ReturnFlag'];
      trans.voidFlag = trxs['VoidFlag'];
      trans.reversalFlag = trxs['ReversalFlag'];
      
      if (Number(trans.reversalFlag) || Number(trans.voidFlag) || Number(trans.returnFlag)) trans.status = trans.reversalFlag === '1' ? 'REVERSAL' : trans.voidFlag === '1' ? 'VOID': 'REFUND';

      trans.transDate = DateService.getDateAndTimeString(new Date(trxs['TransDate'].slice(0, -6)));
      if (trans.timeZone) trans.transDate += `(${trans.timeZone})`;
      transactions.push(trans);
    }
    page.data = transactions;
    return page;
  }

  static toListParam(during, trans: Transaction, page?: Pagination<any>) {
    let param = {};
    param['BeginDate'] = DateService.DateoffsetString(during.begin);
    param['EndDate'] = DateService.DateoffsetString(during.end,true);
    if (trans.traceNum) param['TraceNum'] = trans.traceNum;
    if (trans.user && trans.user !== Constant.ALL) param['User'] = trans.user;
    if (trans.userKey) param['UserKey'] = trans.userKey;
    if (trans.tenderType && trans.tenderType !== Constant.ALL) param['TenderType'] = trans.tenderType;
    if (trans.transType && trans.transType !== Constant.ALL) param['TransType'] = trans.transType;
    if (trans.cardType && trans.cardType !== Constant.ALL) param['CardType'] = trans.cardType;
    if (trans.transResult && trans.transResult !== Constant.ALL) param['TransResult'] = trans.transResult === Constant.SUCCESS ? Constant.ACTIVE_KEY : Constant.FAILURE_KEY;

    if (page) {
      param['PageSize'] = page.pageSize;
      param['PageIndex'] = page.currPage;
    }

    return param;
  }

  static toTransactionModel(res): Transaction {
    let trans: Transaction = new Transaction();
    trans.orderNum = res['OrderNum'];
    trans.orderGuid = res['OrderGuid'];
    trans.paymentType = res['PaymentType'];
    trans.transType = res['TransType'];
    trans.cardNum = res['AcctNum'];
    trans.cardType = res['AcctType'];
    trans.authAmt = res['AuthAmt'];
    trans.approvedAmt = res['ApprovedAmt'];
    trans.tipAmt = res['TipAmt'];
    trans.cashBackAmt = res['CashBackAmt'];
    trans.taxAmt = res['TaxAmt'];
    trans.surchargeAmt = res['SurchargeAmt'];
    trans.totalAmt = res['TotalAmt'];
    trans.fuelAmt = res['FuelAmt'];
    trans.terminalSN = res['TerminalSN'];
    trans.terminalRefNum = res['TerminalRefNum'];
    trans.terminalTransDate = res['TerminalTransDate'];
    trans.terminalTransTime = res['TerminalTransTime'];
    // trans.resultCode = res['ResultCode'];
    // trans.resultMsg = res['ResultMsg'];
    trans.transResultCode = res['TransResultCode'];
    trans.transResultMsg = res['TransResultMsg'];
    trans.approvalCode = res['ApprovalCode'];
    trans.traceNum = res['TraceNum'];
    trans.ecrRefNum = res['ECRRefNum'];
    trans.hostRefNum = res['HostRefNum'];
    trans.hostCode = res['HostCode'];
    trans.hostToken = res['HostToken'];
    trans.hostMsg = res['HostMsg'];
    trans.batchNum = res['BatchNum'];
    trans.voidFlag = res['VoidFlag'];
    trans.origTransNum = res['OriginalTraceNum'];
    trans.email = res['Email'];
    trans.user = res['UserName'];
    trans.entryMode = res['EntryModel'];
    trans.signatureData = res['SignData'];
    trans.discountAmt = res['DiscountAmt'];
    trans.returnFlag = res['ReturnFlag'];
    trans.returnAmt = res['ReturnAmt'];
    trans.returnTipAmt = res['ReturnTipAmt'];
    trans.note = res['Note'];
    trans.description = res['Description'];
    trans.timeZone = res['TimeZone'];
    trans.userDeleted = res['UserDeleted'];
    trans.reversalFlag = res['ReversalFlag'];
    trans.cardPresentFlag = res['CardPresentFlag'];
    trans.transDate = DateService.getDateAndTimeString(new Date(res['TransDate'].slice(0, -6)));
    if (trans.timeZone) trans.transDate += `(${trans.timeZone})`;
    return trans;
  }

  getTransTypeDictionary(): Promise<Response<{ tenderList: Array<string>, transTypeList: Array<string>, cardTypeList: Array<string> }>> {
    let response: Response<{ tenderList: Array<string>, transTypeList: Array<string>, cardTypeList: Array<string> }> =
        new Response<{ tenderList: Array<string>, transTypeList: Array<string>, cardTypeList: Array<string> }>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Transaction/GetTransTypeDictionary').subscribe(res => {
        response.data = {
          tenderList: res['TenderType'],
          transTypeList: CustomService.transTypeList(res['TransType']),
          cardTypeList: res['CardType']
        };
        resolve(response);
      }, error => reject(error));
    });
  }

  getTransactionList(trans, during, page: Pagination<any>): Promise<Response<Pagination<Transaction>>> {
    let response: Response<Pagination<Transaction>> = new Response<Pagination<Transaction>>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Transaction/GetTransactionList', {
        data: TransactionService.toListParam(during, trans, page)
      }).subscribe(res => {
        response.data = TransactionService.toPageTransaction(res);
        resolve(response);
      }, error => reject(error));
    });
  }

  getTransactionDetails(traceNum): Promise<Response<Transaction>> {
    let response: Response<Transaction> = new Response<Transaction>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Transaction/GetTransactionDetails', {restful: {'TraceNum': traceNum}}).subscribe(res => {
        response.data = TransactionService.toTransactionModel(res);
        resolve(response);
      }, error => reject(error));
    });
  }

  getAnonyReceipt(traceId): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/AnonyReceipt/Details', {restful: {'TraceId': traceId}}).subscribe(res => {
        response.data = res['Receipt'] ? Constant.IMG_BASE64_PREFIX + res['Receipt'] : '';
        resolve(response);
      }, error => reject(error));
    });
  }

  sendAnonyReceipt(traceId, email): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/AnonyReceipt/SendEmail', {data: {'TraceId': traceId, 'Email': email}}).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }

  sendAnonyReceipt2(traceId, email, imagetext, token): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/AnonyReceipt/SendEmail2', {data: {
        'TraceId': traceId,
        'Email': email,
        'CaptchaImageText': imagetext,
        'CaptchaToken': token
      }}).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }

  getReceiptPicture(traceNum): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Payment/GetReceiptPicture', {data: {'TraceNum': traceNum}}).subscribe(res => {
        response.data = res['Receipt'] ? Constant.IMG_BASE64_PREFIX + res['Receipt'] : '';
        resolve(response);
      }, error => reject(error));
    });
  }

  sendReceipt(traceNum, email, phone): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/payment/SendReceipt', {data: {'TraceNum': traceNum, 'Email': email, 'Mobile': phone}}).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }

  getDetailsReport(during, trans = new Transaction()): Promise<Response<boolean>> { // download
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.getFile('/Transaction/GetDetailsReport', {
        restful: TransactionService.toListParam(during, trans)
      }).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }


  getUserDetailsReport(during, trans = new Transaction()): Promise<Response<boolean>> { // download
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.getFile('/Transaction/GetLoggedInUserDetailsReport', {
        restful: TransactionService.toListParam(during, trans)
      }).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }
}
