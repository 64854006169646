export class ContinuePayResp{
  purchaseToken: string;
  completeUrl: string;
  invoiceNum: string;
  failUrl: string;
  constructor(options: {
    purchaseToken?: string;
    completeUrl?: string;
    invoiceNum?: string;
    failUrl?: string;
  } = {}) {
    this.purchaseToken  = options.purchaseToken  || '';
    this.completeUrl = options.completeUrl || '';
    this.invoiceNum = options.invoiceNum || '';
    this.failUrl = options.failUrl || '';
  }
}