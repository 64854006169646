export class GetTotalPriceResp {
  subtotal: number;
  shipping: number;
  salesTax: number;
  total: number;
  purchaseToken: string;
  completeUrl: string;
  invoiceNum: string;
  failUrl: string;

  constructor(options: {
    subtotal?: number;
    shipping?: number;
    salesTax?: number;
    total?: number;
    purchaseToken?: string;
    completeUrl?: string;
    invoiceNum?: string;
    failUrl?: string;
  } = {}) {
    this.subtotal  = options.subtotal  || 0;
    this.shipping  = options.shipping  || 0;
    this.salesTax  = options.salesTax  || 0;
    this.total  = options.total  || 0;
    this.purchaseToken  = options.purchaseToken  || '';
    this.completeUrl = options.completeUrl || '';
    this.invoiceNum = options.invoiceNum || '';
    this.failUrl = options.failUrl || '';
  }
}