export class CartProduct {
  cartProductId : number;
  productName: string;
  productPrice: number;
  productImgURL: string;
  quantity: number;
  subtotal: number;
  isDelete: boolean;
  unitPrice: number;
  status: string;

  constructor(options: {
    cartProductId ?: number,
    productName?: string,
    productPrice?: number,
    productImgURL?: string,
    quantity?: number,
    subtotal?: number,
    isDelete?:boolean,
    unitPrice?:number;
    status?:string;
  } = {}) {
    this.cartProductId  = options.cartProductId  || 0;
    this.productName = options.productName || '';
    this.productPrice = options.productPrice || 0;
    this.productImgURL = options.productImgURL || '';
    this.quantity = options.quantity || 1;
    this.subtotal = options.subtotal || 0;
    this.isDelete = options.isDelete || false;
    this.unitPrice = options.unitPrice || 0;
    this.status = options.status || '';
  }
}