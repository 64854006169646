import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShopService } from 'src/app/services/shop.service';

@Component({
  selector: 'payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss']
})
export class PaymentResultComponent implements OnInit {

  resultType: string

  constructor(private route: ActivatedRoute, private shopService: ShopService) { 
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      this.resultType = queryParams.resultType
      if (this.resultType == 'isFromReceiptpPage') {
        window.top.postMessage({resultType: this.resultType}, location.origin)
      }
    })
  }
  jumpToShop() {
    window.top.postMessage({resultType: this.resultType}, location.origin)
  }
}
