import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {TwoFactorLoginComponent} from './pages/two-factor-login/two-factor-login.component';
import {AuthGuard, LoginGuard} from './guards/auth.guard';
import {MobileGuard} from './guards/mobile.guard';
import {ForgetPasswordComponent} from './pages/forget-password/forget-password.component';
import {InitialComponent} from './pages/initial/initial.component';
import {AnonyReceiptComponent} from './pages/anony-receipt/anony-receipt.component';
import {ResetComponent} from './pages/reset/reset.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { ValidateEmailComponent } from './pages/validate-email/validate-email.component';
import { PaymentResultComponent } from './pages/payment-result/payment-result.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
  {path: 'sign-up', component: SignUpComponent, canActivate: [AuthGuard]},
  {path: 'twofactorlogin', component: TwoFactorLoginComponent},
  {path: 'forgot-password', component: ForgetPasswordComponent},
  {path: 'sign-up', component: SignUpComponent},
  {path: 'validate-email', component: ValidateEmailComponent},
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: 'initial', component: InitialComponent},
  {path: 'reset', component: ResetComponent},
  {path: 'receipt/:traceId', component: AnonyReceiptComponent},
  {path: 'payment-result', component: PaymentResultComponent},
  {path: 'mobile', loadChildren: './mobile/mobile.module#MobileModule', canActivate: [AuthGuard]},
  {path: '', loadChildren: './pages/main/main.module#MainModule', canActivate: [AuthGuard, MobileGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
