import {ComponentFactoryResolver, ComponentRef, ReflectiveInjector, ApplicationRef, Injectable, EventEmitter} from '@angular/core';

declare var $: any;

@Injectable()
export class ModalService {
  // private modals: Modal[] = [];

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private applicationRef: ApplicationRef) {
  }

  create(component, options) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const injector = ReflectiveInjector.resolveAndCreate([{provide: 'options', useValue: options}]);
    const componentRef = componentFactory.create(injector);
    this.applicationRef.attachView(componentRef.hostView);
    let tag = document.getElementsByTagName('app-root');
    if (tag.length) tag[0].appendChild(componentRef.location.nativeElement);
    const modal = new Modal(componentRef);
    // this.modals.push(modal);
    return modal;
  }

  destory(modal: Modal) {
    modal.hide();
    // this.modals.splice(this.modals.indexOf(modal), 1);
    modal.$el.remove();
    modal.componentRef.destroy();
  }
}

export class Modal {
  $el;
  $modalEl;
  public componentRef;
  public onShown = new EventEmitter();
  public onHidden = new EventEmitter();

  constructor(componentRef) {
    this.$el = $(componentRef.location.nativeElement);
    this.$modalEl = this.$el.find('.modal');
    this.componentRef = componentRef;
    this.$modalEl.on('shown.bs.modal', (e) => {
      this.onShown.emit(e);
    });
    this.$modalEl.on('hidden.bs.modal', (e) => {
      this.$el.remove();
      this.componentRef.destroy();
      this.onHidden.emit(e);
    });
  }

  show() {
    this.$modalEl.modal('show');
  }

  hide() {
    this.$modalEl.modal('hide');
  }
}
