import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  host: {class: 'up-login-container'},
  styleUrls: ['./validate-email.component.scss']
})
export class ValidateEmailComponent implements OnInit {

  constructor(private routeInfo: ActivatedRoute, private router: Router,) { }

  userEmail = ''

  ngOnInit() {
    this.routeInfo.queryParams.subscribe(params => {
      this.userEmail = params['email'];
      });
  }

  goToSignIn () {
    this.router.navigate(['/login']);
  }

  contactUs() {
    window.open('https://www.bankofamerica.com/customer-service/contact-us/')
  }

}
