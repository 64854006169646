import {Constant} from './constant';

export const Menu = [

  {
    name: 'Order', router: '', active: false, icon: 'sp-icon-dollar', key: '',
    child: [
      {name: 'All', router: '/order/all', active: false, key: Constant.ALWAYS_SHOW,},
      {name: 'Ordered', router: '/order/ordered', active: false, key: Constant.ALWAYS_SHOW,},
      {name: 'Cancel', router: '/order/cancel', active: false, key: Constant.ALWAYS_SHOW,},
      {name: 'Return', router: '/order/return', active: false, key: Constant.ALWAYS_SHOW,},
    ]
  },
  {
    name: 'Account', router: '/account', active: false, icon: 'sp-icon-dollar', key: Constant.ALWAYS_SHOW,
  },
  // {
  //   name: 'Dashboard', router: '/dashboard', active: false, icon: 'sp-icon-clock', key: 'dashboard'
  // },
  // {
  //   name: 'Shop', router: '/shop/hardware', active: false, icon: 'sp-icon-dollar', key: Constant.ALWAYS_SHOW,
  // },
  // {
  //   name: 'Transactions', router: '', active: false, icon: 'sp-icon-transaction1', key: '',
  //   child: [
  //     {name: 'Payments', router: '/transactions/payments', active: false, key: 'transaction_view_all'},
  //     {name: 'Voids', router: '/transactions/voids', active: false, key: 'transaction_view_void'},
  //     {name: 'Refunds', router: '/transactions/refunds', active: false, key: 'transaction_view_refund'},
  //   ]
  // },
  // {
  //   name: 'Reports', router: '', active: false, icon: 'sp-icon-PaymentReport', key: 'report',
  //   child: [
  //     {name: 'Transaction Report', router: '/reports/transaction', active: false, key: 'report_transaction'},
  //     {name: 'Employee Report', router: '/reports/employee', active: false, key: 'report_employee'}
  //   ]
  // },
  {
    name: 'Inventory', router: '', active: false, icon: 'sp-icon-Inventory3', key: 'inventory',
    child: [
      {name: 'Devices', router: '/inventory/devices', active: false, icon: 'sp-icon-dollar', key: Constant.ALWAYS_SHOW,}
      // {name: 'Items', router: '/inventory/items', active: false, key: 'item_view'},
      // {name: 'Categories', router: '/inventory/categories', active: false, key: 'category_view'},
      // {name: 'Stock Management', router: '/inventory/stock', active: false, key: 'stock_management_view'},
      // {name: 'Modifier Group', router: '/inventory/modifier-group', active: false, key: 'modifier_group_view'},
      // {name: 'Tax', router: '/inventory/tax', active: false, key: 'tax_view'},
      // {name: 'Tax Categories', router: '/inventory/tax-category', active: false, key: 'tax_category_view'},
    ]
  },
  // {
  //   name: 'Users', router: '', active: false, icon: 'sp-icon-users2', key: '',
  //   child: [
  //     {name: 'Users', router: '/users/users', active: false, key: 'user_view_all'},
  //     {name: 'Roles', router: '/users/roles', active: false, key: 'role_view_all'}
  //   ]
  // },
  // {
  //   name: 'Merchant', router: '', active: false, icon: 'sp-icon-merchant1', key: '',
  //   child: [
  //     {name: 'Merchant Info', router: '/merchant/info', active: false, key: 'merchant_view_detail'},
  //     {name: 'Receipt', router: '/merchant/receipt', active: false, key: 'merchant_receipt_view'},
  //     {name: 'Device', router: '/merchant/device', active: false, key: 'merchant_terminal_view'},
  //     {name: 'Merchant Settings', router: '/merchant/settings', active: false, key: 'setting_config_view'},
  //     {name: 'Merchant List', router: '/merchant/list', active: false, key: Constant.ALWAYS_SHOW},
  //   ]
  // },
  // {
  //   name: 'Shop', router: '/shop/hardware', active: false, icon: 'sp-icon-dollar', key: Constant.ALWAYS_SHOW,
  // },
  // {
  //   name: 'Profile', router: '/profile', active: false, icon: 'sp-icon-user_edit', key: Constant.ALWAYS_SHOW
  // },
  // {
  //   name: 'Developers', router: '', active: false, icon: 'sp-icon-Keyboard', key: '',
  //   child: [
  //     {name: 'Overview', router: '/developers/overview', active: false, key: Constant.ALWAYS_SHOW},
  //     {name: 'API keys', router: '/developers/api-keys', active: false, key: Constant.ALWAYS_SHOW},
  //     {name: 'Sandbox', router: '/developers/sandbox', active: false, key: Constant.ALWAYS_SHOW},
  //   ]
  // }
];


export const MobMenu = [
  {
    name: 'Dashboard', router: '/mobile/dashboard', active: false, icon: 'sp-icon-clock', key: 'dashboard'
  },
  {
    name: 'Transactions',
    router: '/mobile/transactions',
    active: false,
    icon: 'sp-icon-transaction1',
    key: 'transaction_view_all'
  },
  {
    name: 'Users', router: '', active: false, icon: 'sp-icon-users2', key: '',
    child: [
      {name: 'Users', router: '/mobile/users', active: false, key: 'user_view_all'},
      {name: 'Roles', router: '/mobile/roles', active: false, key: 'role_view_all'}
    ]
  },
  {
    name: 'Merchant', router: '', active: false, icon: 'sp-icon-merchant1', key: '',
    child: [
      {name: 'Merchant Info', router: '/mobile/merchant/detail', active: false, key: 'merchant_view_detail'},
      {name: 'Receipt', router: '/mobile/merchant/receipt/details', active: false, key: 'merchant_receipt_view'},
      {name: 'Device', router: '/mobile/merchant/devices', active: false, key: 'merchant_terminal_view'},
      {name: 'Merchant List', router: '/mobile/merchant', active: false, key: Constant.ALWAYS_SHOW}
    ]
  },
  {
    name: 'Profile', router: '/mobile/profile', active: false, icon: 'sp-icon-user_edit', key: Constant.ALWAYS_SHOW
  }
];

export const BuildMenu = (authority) => {
  let judge = (item, children) => {
    console.log('authority: ' + JSON.stringify(authority, null, 4));

    // return (item.key === Constant.ALWAYS_SHOW || authority[item.key] || children.length);
    return true
  };
  return PushMenu(Menu, judge);
};

export const BuildMobMenu = (authority) => {
  let judge = (item, children) => {
    return (item.key === Constant.ALWAYS_SHOW || authority[item.key] || children.length);
  };
  return PushMenu(MobMenu, judge);
};

export const PushMenu = (menus, judge) => {
  let list = [];
  for (let item of menus) {
    let children = [];
    if (item.child) {
      children = PushMenu(item.child, judge);
    }

    if (judge(item, children)) {

      list.push({name: item.name, router: item.router, active: false, icon: item.icon, child: children});
    }
  }
  return list;
};

export const FindFirstPage = (authority) => {
  let menus = BuildMenu(authority);
  let router = '';
  console.log(menus, 'meus')
  if (menus[0].child.length) router = menus[0].child[0].router;
  else router = menus[0].router;
  return router;
};
