import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';
import {ValidatorsRules} from './validators-rules';

@Directive({
  selector: '[validators]'
})
export class ValidatorsDirective implements OnChanges {

  @Input() validators: any;
  @Input() validators_submitted: boolean;
  div: ElementRef;

  constructor(private elementRef: ElementRef, private _renderer: Renderer2) {
  }

  get element() {
    return this.elementRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let parent = this._renderer.parentNode(this.element);
    this.clean(this.element, parent);
    if (!this.validators_submitted) return;
    if (this.validators) this.addTip(this.element, parent, this.validators);
  }

  clean(el, parent) {
    let _renderer = this._renderer;
    if (this.div) _renderer.removeChild(parent, this.div);
    _renderer.removeClass(el, 'is-invalid');
  }

  addTip(el, parent, validMsg) {
    this._renderer.addClass(el, 'is-invalid');
    this.div = this.createMsg(validMsg);
    this._renderer.appendChild(parent, this.div);
  }

  createMsg(validMsg) {
    let _renderer = this._renderer;
    let div: ElementRef = _renderer.createElement('div');
    Object.keys(validMsg).forEach((key, index) => {
      if (ValidatorsRules[key] && key === 'maxlength') {
        _renderer.appendChild(div, this.createSpan( ValidatorsRules[key]['message'].replace('{0}', validMsg[key].requiredLength), index));
      } else if (ValidatorsRules[key] && key === 'minlength') {
        _renderer.appendChild(div, this.createSpan( ValidatorsRules[key]['message'].replace('{0}', validMsg[key].requiredLength), index));
      } else if (ValidatorsRules[key] && key === 'max') {
        _renderer.appendChild(div, this.createSpan( ValidatorsRules[key]['message'].replace('{0}', validMsg[key].max), index));
      } else if (ValidatorsRules[key] && key === 'min') {
        _renderer.appendChild(div, this.createSpan( ValidatorsRules[key]['message'].replace('{0}', validMsg[key].min), index));
      }else if(ValidatorsRules[key] && key === 'maxQuantity'){
        let text = ValidatorsRules[key]['message'].replace('{0}', validMsg[key].max)
        text = text.replace('{1}', validMsg[key].formControlName)
        _renderer.appendChild(div, this.createSpan(text , index));
      }else if(ValidatorsRules[key] && key === 'minQuantity'){
        let text = ValidatorsRules[key]['message'].replace('{0}', validMsg[key].min)
        text = text.replace('{1}', validMsg[key].formControlName)
        _renderer.appendChild(div, this.createSpan(text , index));
      }else if (ValidatorsRules[key] && key === 'illegal') { 
        if(validMsg.illegal)
          _renderer.appendChild(div, this.createSpan( ValidatorsRules[key]['message'].replace('This value is illegal', validMsg.illegal), index)); 
        else{
          _renderer.appendChild(div, this.createSpan( ValidatorsRules[key]['message'], index)); 
        }
      }else if (ValidatorsRules[key]) _renderer.appendChild(div, this.createSpan(ValidatorsRules[key]['message'], index));
      
    });
    _renderer.addClass(div, 'invalid-feedback');
    return div;
  }

  createSpan(text, index) {
    let _renderer = this._renderer;
    let span = _renderer.createElement('span');
    if (index) _renderer.appendChild(span, _renderer.createElement('br'));
    _renderer.appendChild(span, _renderer.createText(text));
    return span;
  }
}
