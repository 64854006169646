import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, of, Subject, throwError} from 'rxjs';
import {catchError, finalize, mergeMap, switchMap, tap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {EncryptService} from '../utils/encrypt.service';
import {Auth} from '../models/auth';
import {LoginService} from './login.service';
import {Constant} from '../constants/constant';
import {DialogService} from '../utils/dialog.service';
import {LoadingService} from '../utils/loading.service';
import {FindFirstPage} from '../constants/menu';
import {Router} from '@angular/router';
import {RoleService} from './role.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  isRefreshingToken: boolean = false;
  isLoggingOut: boolean = false;

  private tokenSubject = new Subject<Auth>();
  private blankSubject = new Subject<any>();

  constructor(private authService: AuthService, private loginService: LoginService, private dialog: DialogService,
              private loading: LoadingService, private roleService: RoleService, private router: Router) {
  }

  //! 这里可以加header，打印请求和响应等一系列操作
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq: HttpRequest<any>
    if (req.urlWithParams.endsWith('/amazona/Service/API/MPOSTokenAPI/Payment/Transact')) {
      authReq = req.headers.keys().length ? req.clone() : req.clone({setHeaders: this.setHeadersWithAuthToken()});
    } else {
      authReq = req.headers.keys().length ? req.clone() : req.clone({setHeaders: this.setHeaders(req)});
    }
    //! print resquest
    console.log('[HTTP Request]: ' + JSON.stringify(authReq, null, 4));
    return next.handle(authReq).pipe(
        mergeMap(event => {
          // 返回response
          if (event instanceof HttpResponse) {
            /*if (event.status === 404) {
              // go to 404 html
              this.router.navigateByUrl('/404');
            }
            if (event.status === 500) {
              // go to 500 html
              this.router.navigateByUrl('/500');
            }*/
          }
          return of(event);
        }),
        //! print response
        tap(event => {
          if (event instanceof HttpResponse){
            console.log('[HTTP Response]: ' + JSON.stringify(event, null, 4));
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse && !error.url.includes('/Security/Logout')) {
            return this.handleError(error, req, next);
          } else return throwError(error);
        })
    );
  }

  handleError(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler) {
    if (error.status === 302) {
      if (!this.isLoggingOut) {
        this.isLoggingOut = true;
        // The current backend handling in the dev portal is such that if another device logs in, it will overwrite the original token and return a 302 status code. Similarly, if the token becomes completely invalid, a 302 status code is also returned. 
        // Therefore, it is currently not possible to differentiate between a completely invalid token and another device login. As a result, a unified message indicating token expiration is displayed.
        // let msg = error.statusText === Constant.TOKEN_MISSING_JWT ? 'LOGIN.HAS_BEEN_LOGOUT' : 'API_ERRORS.LOGGED_OTHER_DEVICE';
        let msg = 'API_ERRORS.SESSION_INVALID'
        this.logout(error, msg);
        return throwError(error);
      } else {
        return this.blankSubject.asObservable();
      }
    } else if (error.status === 307) {
      if (!this.isRefreshingToken) return this.refreshToken(req, next); // token失效，refresh
      else {
        return this.tokenSubject.asObservable().pipe(  // 监听refresh，成功后再次发送请求
            switchMap((auth) => {
              return next.handle(req.clone({setHeaders: this.setHeaders(req)}));
            })
        );
      }
    } else if (error.status === 401) {
      // why call changePermission() here?
      if(error.error['resultMsg']) {
       return throwError(error.error);
      }
      this.changePermission();
      return throwError(error);
    } else if (error.status === 403) {
      if (!this.isLoggingOut) {
        this.isLoggingOut = true;
        let msg = 'API_ERRORS.ACCOUNT_INACTIVE';
        this.logout(error, msg);
        return throwError(error);
      } else {
        return this.blankSubject.asObservable();
      }
    } else return throwError(error);
  }

  changePermission() {
    let alert = this.dialog.alert('USER.PERMISSION_CHANGED');
    alert.onHidden.subscribe(() => {
      this.loading.show();
      let role = this.authService.getSession('role');
      this.roleService.getLoggedAuthority().then((res_authority) => { // 获取当前权限
        this.authService.setAuthority(res_authority.data);
        let router = FindFirstPage(res_authority.data);
        location.href = location.href.replace(this.router.url, '') + router;
      }).catch((error) => {
        this.dialog.error(error);
      }).finally(() => {
        this.loading.hide();
      });
    });
  }

  logout(error: HttpErrorResponse, msg: string = 'API_ERRORS.SESSION_TIMEOUT') {
    let alert = this.dialog.alert(msg, 'COMMON.ATTENTION');
    alert.onHidden.subscribe(() => {
      this.loginService.logout();
      this.isLoggingOut = false;
    });
    if (!this.loading.hidden) this.loading.hide();
    error['flag'] = Constant.REFRESH_TOKEN_ERROR;
  }

  refreshToken(req: HttpRequest<any>, next: HttpHandler) {
    this.isRefreshingToken = true;
    let refresh = this.authService.refreshToken();
    return refresh.pipe(
        switchMap((auth) => {
          this.authService.setAuth(auth);
          this.tokenSubject.next(auth);
          return next.handle(req.clone({setHeaders: this.setHeaders(req)}));
        }),
        catchError(error => {
          this.logout(error);
          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
    );
  }

  private setHeaders(req: HttpRequest<any>) {
    let auth = this.authService.getAuth();
    let loginFlag = this.authService.getLoginFlag();
    if (!loginFlag && !auth) return {'Content-Type': 'application/json'};

    let reqstr = req.serializeBody() ? req.serializeBody().toString() : '';
    let text = req.method.toUpperCase() === 'GET' ? req.params.toString() : reqstr;
    let method = req.method.toUpperCase();
    if(req.body instanceof FormData) {
      return {
        'Authorization': auth.accessToken,
        'AccessKeyID': auth.accessKeyID,
        'Timestamp': new Date().toISOString().substring(0, 19),
        'Signature': auth.uptmpasid ? EncryptService.signature(method, text, auth.uptmpasid) : '',
      }
    }

    return {
      'Content-Type': 'application/json',
      // 'Authorization': `${auth.tokenType} ${auth.accessToken}`,
      'Authorization': auth.accessToken,
      'AccessKeyID': auth.accessKeyID,
      'Timestamp': new Date().toISOString().substring(0, 19),
      'Signature': auth.uptmpasid ? EncryptService.signature(method, text, auth.uptmpasid) : '',
      // 'SignatureData': EncryptService.signatureData(method, timeStamp + text) //! need consideration
    };
  }

  private setHeadersWithAuthToken() {
    const auth = this.authService.getAuth();

    return {
      'Content-Type': 'application/json',
      'Authorization': `${auth.tokenType} ${auth.authToken}`,
      'Environment': 'SANDBOX'
    };
  }
}
