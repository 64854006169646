import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {FindFirstPage} from '../constants/menu';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin();
  }

  checkLogin(): boolean {
    let loginFlag = this.authService.getLoginFlag();
    if (!loginFlag) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin();
  }

  checkLogin(): boolean {
    let loginFlag = this.authService.getLoginFlag();
    if (loginFlag) {
      let router = FindFirstPage(this.authService.getAuthority());
      this.router.navigate(['/' + router]);
      return false;
    }
    return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate{
  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let per = route.data.permission;
    let permission = this.authService.getAuthority();
    
    if (per && permission[per]) {
      return true;
    } else {
      this.router.navigate(['/' + 'notfound']);
      return false;
    } 
  }
}
