import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import { Product } from '../models/product';
import {Response} from '../models/response';
import { HardwareOrder } from '../models/hardwareOrder';
import { CartProduct } from '../models/cartProduct';
import { Pagination } from '../models/pagination';
import {DateService} from '../utils/date.service';
import { GetTotalPriceResp } from '../models/GetTotalPriceResp';
import { ContinuePayResp } from '../models/continuePayResp';
import { VerfifyAssdressResp } from '../models/verifyAddressResp';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  cartQuantity: string = '0'
  constructor(private http: HttpService) { 
  }

  static toProductModel(res): Product {
    let product = new Product()
    for (let key in res.productDetail) {
      product[key] = res.productDetail[key]
    }
    return product
  }
  static toCartProductModel(res): CartProduct {
    let cartProduct = new CartProduct()
    cartProduct.isDelete = res.isDelete
    cartProduct.cartProductId = res.cartProductId
    cartProduct.quantity = res.quantity
    cartProduct.subtotal = res.subtotal
    return cartProduct
  }

  static toHardwareOrderModelList(res): Pagination<HardwareOrder> {
    let page: Pagination<HardwareOrder> = new Pagination<HardwareOrder>(res)
    
    res.orderHistory.forEach(element => {
      element.orderDate = DateService.getDateAndTimeString(new Date(element.orderDate));
    });
    page.data = res.orderHistory
    return page
  }

  static toHardwareOrderModel(res): HardwareOrder{
    const hardwareOrder: HardwareOrder = new HardwareOrder()
    const products: Array<CartProduct> = []
    for (let key in res.orderDetails) {
      if (key != 'products' && key != 'orderDate') {
        hardwareOrder[key] = res.orderDetails[key]
      }
    }
    for (let item of res.orderDetails['products']) {
      const product: CartProduct = new CartProduct()
      product.productName = item.name
      product.productImgURL = item.productImgURL
      product.quantity = item.quantity
      product.subtotal = item.subtotal
      product.unitPrice = item.unitPrice
      product.status = item.status
      products.push(product)
    }
    hardwareOrder.orderDate = DateService.getDateAndTimeString(new Date(res.orderDetails['orderDate']));
    hardwareOrder.products = products
    return hardwareOrder
  }

  static toTotalPriceModel(res): GetTotalPriceResp {
    const getTotalPriceResp = new GetTotalPriceResp()
    getTotalPriceResp.salesTax = res.salesTax
    getTotalPriceResp.shipping = res.shipping
    getTotalPriceResp.subtotal = res.subtotal
    getTotalPriceResp.total = res.total
    getTotalPriceResp.purchaseToken = res.purchaseToken
    getTotalPriceResp.completeUrl = res.completeUrl
    getTotalPriceResp.invoiceNum = res.invoiceNum
    getTotalPriceResp.failUrl = res.failUrl
    return getTotalPriceResp
  }

  static toContinuePayRespModel(res): ContinuePayResp{
    const continuePayResp = new ContinuePayResp()
    continuePayResp.completeUrl = res.completeUrl
    continuePayResp.invoiceNum = res.invoiceNum
    continuePayResp.purchaseToken = res.purchaseToken
    continuePayResp.failUrl = res.failUrl
    return continuePayResp
  }
  static toVerfifyAssdressRespModel(res): VerfifyAssdressResp{
    const verifyAddressResp = new VerfifyAssdressResp()
    verifyAddressResp.address = res.address
    verifyAddressResp.address2 = res.address2
    verifyAddressResp.city = res.city
    verifyAddressResp.zip4 = res.zip4
    verifyAddressResp.zip5 = res.zip5
    return verifyAddressResp
  }


  getProductsByCategory(category: string = 'All'): Promise<Response<Array<Product>>> {
    let response: Response<Array<Product>> = new Response<Array<Product>>();
    return new Promise((resolve, reject) => {
      this.http.get('/amazona/Shop/Products/GetProductsByCategory', {
        restful: {'category': category}
      }).subscribe(res => {
        response.data = res.products
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  }
  GetOneItem(productId: number): Promise<Response<Product>> {
    let response: Response<Product> = new Response<Product>()
    return new Promise((resolve, reject) => {
      this.http.get('/amazona/Shop/Products/GetOneItem', {
        restful: {'productId': productId}
      }).subscribe(res => {
        response.data = res.productDetail
        resolve(response)
      }, error => reject(error))
    })
  }

  getCartList(): Promise<Response<Array<CartProduct>>> {
    let response: Response<Array<CartProduct>> = new Response<Array<CartProduct>>()
    return new Promise((resolve, reject) => {
      this.http.get('/amazona/Shop/Cart/GetCart').subscribe(res => {
        response.data = res.cart
        resolve(response)
      }, error => reject(error))
    })
  }

  addToCart(params) {
    return new Promise((resolve, reject) => {
      this.http.post('/amazona/Shop/Cart/AddToCart', {
        data: params
      }).subscribe(res => {
        resolve(res)
      }, error => reject(error))
    })
  }

  EditCart(params): Promise<Response<CartProduct>> {
    let response: Response<CartProduct> = new Response<CartProduct>()
    return new Promise((resolve, reject) => {
      this.http.put('/amazona/Shop/Cart/EditCart', params).subscribe(res => {
        response.data = ShopService.toCartProductModel(res)
        resolve(response)
      }, error => reject(error))
    })
  }

  GetOrderHistory(params): Promise<Response<Pagination<HardwareOrder>>>{
    let response: Response<Pagination<HardwareOrder>> = new Response<Pagination<HardwareOrder>>()
    return new Promise((resolve, reject) => {
      this.http.post('/amazona/Shop/Orders/GetOrderHistory', {
        data: params
      }).subscribe(res => {
        response.data = ShopService.toHardwareOrderModelList(res)
        resolve(response)
      }, error => reject(error))
    })
  }

  GetOrderDetails(orderId): Promise<Response<HardwareOrder>> {
    let response: Response<HardwareOrder> = new Response<HardwareOrder>()
    return new Promise((resolve, reject) => {
      this.http.get('/amazona/Shop/Orders/GetOrderDetails', {
        restful: {orderId: orderId}
      }).subscribe(res => {
        res.orderDetails.orderDate = DateService.getDateAndTimeString(new Date(res.orderDetails['orderDate']));
        response.data = ShopService.toHardwareOrderModel(res)
        resolve(response)
      }, error => reject(error))
    })
  }
  checkoutPay(params): Promise<Response<string>>{
    let response: Response<string> = new Response<string>()
    return new Promise((resolve, reject) => {
      this.http.post('/amazona/Shop/Checkout/Pay', {
        data: params
      }).subscribe(res => {
        response.data = res.orderId
        resolve(response)
      }, error => reject(error))
    })
  }


  getCartQuantity() {
    return new Promise<void>((resolve, reject) => {
      this.getCartList().then(res => {
        const sum = res.data.reduce((pre, cur) => pre + cur.quantity, 0)
        this.cartQuantity = sum < 99 ? String(sum) : '99+'
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }

  verifyAddress(params):Promise<Response<VerfifyAssdressResp>> {
    let response = new Response<VerfifyAssdressResp>()
    return new Promise((resolve, reject) => {
      this.http.post('/amazona/Shop/Checkout/VerifyAddress', {
        data: params
      }).subscribe(res => {
        response.data = ShopService.toVerfifyAssdressRespModel(res)
        resolve(response)
      }, error => reject(error))
    })
  }

  getTotalRequest(params):Promise<Response<GetTotalPriceResp>> {
    let response = new Response<GetTotalPriceResp>()
    return new Promise((resolve, reject) => {
      this.http.post('/amazona/Shop/Checkout/GetTotalPrice', {
        data: params
      }).subscribe(res => {
        response.data = ShopService.toTotalPriceModel(res)
        resolve(response)
      }, error => reject(error))
    })
  }
  

  updateOrderStatus(params):Promise<Response<string>> {
    let response = new Response<string>()
    return new Promise((resolve, reject) => {
      this.http.put('/amazona/Shop/Orders/UpdateOrderStatus', params).subscribe(res => {
        response.data = res.status
        resolve(response)
      }, error => reject(error))
    })
  }

  cancelOrderStatus(params):Promise<Response<string>> {
    let response = new Response<string>()
    return new Promise((resolve, reject) => {
      this.http.put('/amazona/Shop/Orders/CancelOrder', params).subscribe(res => {
        response.data = res.status
        resolve(response)
      }, error => reject(error))
    })
  }

  continuePay(params): Promise<Response<ContinuePayResp>>{
    let response = new Response<ContinuePayResp>()
    return new Promise((resolve, reject) => {
      this.http.put('/amazona/Shop/Checkout/ContinuePay', params).subscribe(res => {
        response.data = ShopService.toContinuePayRespModel(res)
        resolve(response)
      }, error => reject(error))
    })
  }
}
