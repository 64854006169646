import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {ProfileService} from '../../services/profile.service';
import {DialogService} from '../../utils/dialog.service';
import {AuthService} from '../../services/auth.service';
import {LoadingService} from '../../utils/loading.service';
import {TranslateService} from '@ngx-translate/core';
import { LoginService } from 'src/app/services/login.service';
import { param } from 'jquery';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {

  @Input() merchantUserKey: string;
  @Output() onCancel = new EventEmitter();
  // EventEmitter<string> = new EventEmitter<string>()
  @Output() onSuccess: EventEmitter<string> = new EventEmitter<string>();

  emailForm: FormGroup;
  email_submitted = false;

  waiting: number = 0;
  loginUserFlag: boolean = false;

  tips: string = '';
  isError: boolean = false;

  constructor(private formBuilder: FormBuilder, private profileService: ProfileService,
              private authService: AuthService, private dialog: DialogService, private loading: LoadingService,
              private translate: TranslateService, private loginService: LoginService) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.loginUserFlag = (this.authService.getAuth().merchantUserKey === this.merchantUserKey);

    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]],
      verificationCode: [''],
      password: ['']
    });
    if (this.loginUserFlag) this.f.verificationCode.setValidators([Validators.required, Validators.maxLength(256)]);
    if (this.loginUserFlag) this.f.password.setValidators([Validators.required]);
  }

  get f() {
    return this.emailForm.controls;
  }

  sendVerificationCode() {
    this.loading.show();
    this.profileService.sendVerificationCode(this.f.email.value, this.f.password.value).then(() => {
      this.tips = this.translate.get('USER.VERIFY_MSG')['value'];
      this.isError = false;
      this.f.email.disable();
      this.waiting = 60;
      this.countdown();
    }).catch(res => this.dialog.error(res)).finally(() => this.loading.hide());
  }

  countdown() {
    if (this.waiting > 0) {
      setTimeout(() => {
        this.waiting--;
        this.countdown();
      }, 1000);
    }
  }

  submitEmail() {
    this.tips = '';
    this.email_submitted = true;
    if (this.emailForm.invalid) return;

    this.editLoginInUserEmail();
    // if (this.loginUserFlag) this.editLoginInUserEmail();
    // else this.editOtherUserEmail();
  }

  editLoginInUserEmail() {
    this.loading.show();
    let email = this.f.email.value;
    // let verificationCode = this.f.verificationCode.value;
    // this.profileService.editLoginInUserEmail(verificationCode).then((res) => {
    //   this.onSuccess.emit(res.data);
    //   this.clear();
    // }).catch(res => this.showError(res)).finally(() => this.loading.hide());
    this.loginService.updateUser({email}).then((res) => {
        this.onSuccess.emit(email);
        this.clear();
      }).catch(res => this.showError(res)).finally(() => this.loading.hide());
  }

  editOtherUserEmail() {
    this.loading.show();
    let email = this.f.email.value;
    this.profileService.editOtherUserEmail(this.merchantUserKey, email).then((res) => {
      this.onSuccess.emit(res.data);
      this.clear();
    }).catch(res => this.showError(res)).finally(() => this.loading.hide());
  }

  cancelEmail(e?) {
    e && e.stopPropagation();
    this.clear();
    this.onCancel.emit(e);
  }

  clear() {
    this.emailForm.patchValue({'email': '', 'verificationCode': ''});
    this.email_submitted = false;
  }

  showError(res) {
    this.tips = this.dialog.error_msg(res);
    this.isError = true;
  }


}
