import {Component, ElementRef, Input, OnInit, Output, TemplateRef, EventEmitter} from '@angular/core';
import { User } from 'src/app/models/user';
import { ProfileService } from 'src/app/services/profile.service';
import {DialogService} from 'src/app/utils/dialog.service';
import {LoadingService} from 'src/app//utils/loading.service';
import {TranslateService} from '@ngx-translate/core';
import {ValidatorsService} from 'src/app//validtors/validators.service';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {AuthService} from 'src/app//services/auth.service';
import {Constant} from 'src/app//constants/constant';
import {MerchantService} from 'src/app//services/merchant.service';
import {Pagination} from 'src/app//models/pagination';
import {Merchant} from 'src/app//models/merchant';
import {Modal} from 'src/app//utils/modal.service';
import { LoginService } from 'src/app/services/login.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-profile',
  templateUrl: './profile-dialog.html',
  styleUrls: ['./profile-dialog.scss']
})
export class ProfileDialogComponent implements OnInit {

  @Input() modal: Modal;
  @Output() editSuccess = new EventEmitter();
  userEdit: boolean = false;
  isChangeMerchant: boolean = false;
  isChangeEmail: boolean = false;
  isChangePwd: boolean = false;
  isChangeCode: boolean = false;
  hasBeenSetCode: boolean = false;

  userForm: FormGroup;
  passwordForm: FormGroup;
  passcodeForm: FormGroup;

  submitted = false;
  password_submitted = false;
  passcode_submitted = false;
  countryList = Constant.COUNTRY_LIST;
  profile: User = new User();

  merchants: Array<{ key: string, val: string }> = [];
  orgPrimaryMerchantKey: string;
  primaryMerchantKey: string;

  passcodeLength: number = 6;
  merchantUserKey: string;
  error: any;
  pagination: Pagination<Merchant> = new Pagination<Merchant>();
  password: string;

  constructor(private profileService: ProfileService, private translate: TranslateService, private authService: AuthService,
              private dialog: DialogService, private formBuilder: FormBuilder, private loading: LoadingService,
              private merchantService: MerchantService, private el: ElementRef, private loginService: LoginService) {
  }

  ngOnInit() {
    this.initForm();
    this.getProfile();
    // this.getMerchantList();
    // this.merchantUserKey = this.authService.getAuth().merchantUserKey;
  }

  closeDialog() {
    this.modal.hide();
  }

  initForm() {
    this.userForm = this.formBuilder.group({
      userName: ['', [Validators.maxLength(60)]],
      firstName: ['', [Validators.required, Validators.maxLength(32)]],
      lastName: ['', [Validators.required, Validators.maxLength(32)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]]
    });

    this.userForm.disable();

    console.log(this.authService.getPsw());

    //  ValidatorsService.password there is no limit of psw.
    this.passwordForm = this.formBuilder.group({
          // oldPwd: ['', [Validators.required, Validators.maxLength(256),
          // ]],
          newPwd: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(256)]],
          confirmPwd: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(256)]],
        }, {
          validators: [
            ValidatorsService.compare('newPwd', 'confirmPwd'),
            // ValidatorsService.compareCurrentPsw('oldPwd', this.authService.getPsw())
          ]
        }
    );

    this.passcodeForm = this.formBuilder.group({
          oldPcd: ['', Validators.maxLength(6)],
          newPcd: [''],
          confirmPcd: [''],
        }, {
          validator: ValidatorsService.compare('newPcd', 'confirmPcd')
        }
    );
  }

  get f() {
    return this.userForm.controls;
  }

  get pwd() {
    return this.passwordForm.controls;
  }

  get pcd() {
    return this.passcodeForm.controls;
  }

  getProfile() {
    this.profile = this.authService.getProfile();
    if(this.authService.getRole()) {
      this.profile.role = this.authService.getRole()
    }
    this.profile.userName = this.profile.firstName + this.profile.lastName;
    this.userForm.patchValue(this.profile);
  }

  editInfo(e) {
    e.stopPropagation();
    this.userEdit = true;
    this.userForm.enable();
    // this.f.email.disable();
  }

  cancelInfo(e?) {
    e && e.stopPropagation();
    this.userEdit = false;
    this.userForm.patchValue(this.profile);
    this.submitted = false;
    this.userForm.disable();
  }

  onSubmit() {
    this.submitted = true;
    if (this.userForm.invalid) return;
    console.log(this.userForm.getRawValue(), 'value')
    const {firstName, lastName} = this.userForm.getRawValue()
    this.loading.show();
    this.loginService.updateUser({
      firstName, lastName
    }).then(res => {
      this.dialog.alertWithoutMsg('PROFILE.CHANGE_PROFILE_SUCCESS');
      this.profile = {...this.profile, ...this.userForm.getRawValue()};
      this.profile.userName = this.profile.firstName + this.profile.lastName;
      console.log(this.profile, 'profile')
      this.authService.setProfile(this.profile);
      this.cancelInfo();
    }).catch(res => this.dialog.error(res)).finally(() =>{ 
      this.loading.hide();
      this.editSuccess.emit();
    });
   
  }

  changePwd(e) {
    e.stopPropagation();
    this.isChangePwd = true;
  }

  cancelPwd(e?) {
    e && e.stopPropagation();
    this.isChangePwd = false;
    // 'oldPwd': '',
    this.passwordForm.patchValue({ 'newPwd': '', 'confirmPwd': ''});
    this.password_submitted = false;
  }

  submitPwd() {
    this.password_submitted = true;
    if (this.passwordForm.invalid) return;
    this.loading.show();
    console.log(this.passwordForm.getRawValue(), 'psw form')
    this.loginService.updateUser({password: CryptoJS.SHA512(this.passwordForm.getRawValue().newPwd).toString() }).then((res) => {
     
      this.profile = {...this.profile, password: this.passwordForm.getRawValue().newPwd};
      
      this.authService.setProfile(this.profile);
      this.dialog.alertWithoutMsg('PROFILE.CHANGE_PROFILE_SUCCESS');
    }).catch(res => this.dialog.error(res)).finally(() => {
      this.loading.hide();
      this.editSuccess.emit();
    });

  }

  changeCode() {
    this.profileService.checkPasscode().then((res) => {
      this.isChangeCode = true;
      this.hasBeenSetCode = res.data.hasBeenSetCode;
      if (!this.hasBeenSetCode) this.passcodeForm.get('oldPcd').clearValidators();
      else this.passcodeForm.get('oldPcd').setValidators([Validators.required, Validators.maxLength(6)]);
      // this.passcodeLength = res.data.passcodeLength;
      let lengthValid = this.passcodeLength === 6 ? ValidatorsService.passcode_6 : ValidatorsService.passcode_4;
      this.passcodeForm.get('newPcd').clearValidators();
      this.passcodeForm.get('confirmPcd').clearValidators();
      this.passcodeForm.get('newPcd').setValidators([Validators.required, lengthValid]);
      this.passcodeForm.get('confirmPcd').setValidators([Validators.required, lengthValid]);
    }).catch(error => this.dialog.error(error));
  }

  cancelCode() {
    this.isChangeCode = false;
    this.passcodeForm.patchValue({'oldPcd': '', 'newPcd': '', 'confirmPcd': ''});
    this.passcode_submitted = false;
  }

  submitCode() {
    this.passcode_submitted = true;
    if (this.passcodeForm.invalid) return;
    if (this.hasBeenSetCode) this.changePasscode();
    else this.setPasscode();
  }

  changePasscode() {
    this.loading.show();
    this.profileService.changePasscode(this.passcodeForm.getRawValue()).then((res) => {
      this.dialog.success('PROFILE.CHANGE_PASSCODE_SUCCESS');
      this.cancelCode();
    }).catch(res => this.dialog.error(res)).finally(() => this.loading.hide());
  }

  setPasscode() {
    this.loading.show();
    this.profileService.setPasscode(this.passcodeForm.getRawValue()).then((res) => {
      this.dialog.success('PROFILE.SET_PASSCODE_SUCCESS');
      this.cancelCode();
    }).catch(res => this.dialog.error(res)).finally(() => this.loading.hide());
  }

  getMerchantList() {
    this.loading.show();
    this.pagination.pageSize = Constant.INT_MAX;
    this.merchantService.getMerchantList('', Constant.ACTIVE, this.pagination).then((res) => {
      this.pagination.totalPage = res.data.totalPage;
      let merchantKey: string = '';
      let merchants: Array<{ key: string, val: string }> = [];
      for (let item of res.data.data) {
        merchants.push({
          key: item.merchantName,
          val: item.merchantKey
        });
        if (item.isPrimary) merchantKey = item.merchantKey;
      }
      if(this.merchants){
        this.merchants = this.merchants.concat(merchants)
      }else{
        this.merchants = merchants;
      }; 
      if(merchantKey)this.orgPrimaryMerchantKey = merchantKey;
      this.cancelMerchant();
    })
        .catch(res => this.dialog.error(res))
        .finally(() => this.loading.hide());
  }

  changeMerchant() {
    this.isChangeMerchant = true;
  }

  submitMerchant() {
    this.loading.show();
    this.merchantService.setPrimaryMerchant(this.primaryMerchantKey).then(() => {
      this.isChangeMerchant = false;
      this.orgPrimaryMerchantKey = this.primaryMerchantKey;
      this.dialog.success();
    }).catch(error => {
      this.dialog.error_msg(error);
    }).finally(() => { 
      this.loading.hide();
    });
  }

  changeTwoFac(){ 
    this.modal.hide();
    this.loading.show();
    let flag = 0;
    if (this.el.nativeElement.querySelector('.sp-switch').checked === true)flag = 1; 
    
    this.merchantService.editTwoFactorAuth(flag, this.password).then(() => {
      this.password = '';
    }).catch(error => {
        this.dialog.error(error);
        this.password = '';
        this.el.nativeElement.querySelector('.sp-switch').checked = !flag;
      }).finally(() => this.loading.hide());
  }

  cancelMerchant() {
    this.isChangeMerchant = false;
    this.primaryMerchantKey = this.orgPrimaryMerchantKey;
  }

  changeEmail(e) {
    e.stopPropagation();
    this.isChangeEmail = true;
  }

  successEmail(email) {
    this.profile.email = email;
    this.dialog.alertWithoutMsg('PROFILE.CHANGE_PROFILE_SUCCESS');
    this.cancelEmail();
  }

  cancelEmail() {
    this.isChangeEmail = false;
  }

  onChange(e): void {
    this.pagination.currPage = e;
    this.getMerchantList();
  }

 psd = {
  showModal:(template: TemplateRef<any>) =>{
    this.modal = this.dialog.modal(template, 'Input your password');
    this.modal.show();
  },
  hideModal: ()=>{
    let flag = this.el.nativeElement.querySelector('.sp-switch').checked;
    this.el.nativeElement.querySelector('.sp-switch').checked = !flag;
    this.modal.hide();
  }
 }
}
