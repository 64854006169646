import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class MobileGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.isPC() && !state.url.startsWith('/mobile')) {
      this.router.navigate(['/mobile']); //  + state.url
      return false;
    }
    return true;
  }

  isPC() {
    let userAgentInfo = navigator.userAgent;
    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    let flag = true;
    for (let agent of Agents) {
      if (userAgentInfo.indexOf(agent) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }
}
