import {Constant} from '../constants/constant';

export class Pagination<T> {
  public pageSize: number;
  public currPage: number;

  public totalCounts: number;
  public totalPage: number;

  public data: Array<T>;

  constructor(res?) {
    if (res) this.dealRes(res);
    else this.init();
    this.data = [];
  }

  dealRes(res) {
    // if res['Count'] = 0, res['count'] = undefined, this.totalCounts will equal undefined
    this.totalCounts = res['Count'] || res['count'] || 0;
    this.currPage = res['PageIndex'] || (res['pageIndex'] + 1) || 0;
    this.pageSize = res['PageSize'] || res['pageSize'] || 0;
    this.totalPage = Math.ceil(this.totalCounts / this.pageSize);
  }

  init() {
    this.currPage = 1;
    this.totalPage = 1;
    this.pageSize = Constant.ui_pagination_pageSize;
  }
}
