import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnInit {
  @Input('tooltip') tooltipText: string;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    const tooltip = document.createElement('div');
    tooltip.className = 'tooltiptext';
    tooltip.textContent = this.tooltipText;
    this.el.nativeElement.classList.add('tooltip-container');
    this.el.nativeElement.appendChild(tooltip);
  }
}
