import {Component, Input, OnInit} from '@angular/core';
import {Constant} from '../../constants/constant';

@Component({
  selector: 'app-password-rules',
  templateUrl: './password-rules.component.html',
  styleUrls: ['./password-rules.component.scss']
})
export class PasswordRulesComponent implements OnInit {

  rules;

  @Input() password: boolean = false;
  @Input() passcode: boolean = false;

  @Input() passcode_length: number;

  constructor() {
  }

  ngOnInit() {
    if (this.password) this.rules = [
      'At least 8 characters.',
      // 'Use at least one special character. (e.g. @, #, $, %, ^, &, *, (, ), _, +, -, =, )',
      // 'Use at least one UPPER and one lower case character.',
      // 'Use at least one number.',
      // 'You cannot use a password that was used recently.'
    ];
    else if (this.passcode) {
      let length = this.passcode_length ? this.passcode_length : Constant.DEFAULT_PASSCODE_LENGTH;
      this.rules = [
        'Use ' + length + ' digital number.',
        'You cannot use a passcode that was used recently.'
      ];
    }
  }

}
