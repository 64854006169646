import {Injectable} from '@angular/core';
import {Pagination} from '../models/pagination';
import {Response} from '../models/response';
import {Item} from '../models/Item';
import {Constant} from '../constants/constant';
import {HttpService} from './http.service';
import {ModifierGroup} from '../models/modifierGroup';
import {DateService} from '../utils/date.service';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(private http: HttpService) { }


  static toPageItem(res): Pagination<Item> {
    let page: Pagination<Item> = new Pagination<Item>(res);
    let ItemsList: Array<Item> = [];
    for (let it of res['ItemList']) {
      let item: Item = new Item();
      item.guid = it['Guid'];
      item.itemName = it['Name'];
      item.displayOrder = it['DisplayOrder'];
      item.price = it['Price'];
      item.priceExclTax = it['Price'];
      item.picture = it['Picture'] ? Constant.IMG_BASE64_PREFIX + it['Picture'] : '';
      item.sku = it['SKU'];
      item.category = it['Category'];
      item.tax = it['Tax'];
      item.stockQuantity = it['StockQuantity'];
      item.taxExempt = it['TaxExempt'];
      item.taxCategoryName = it['TaxCategoryName'];
      ItemsList.push(item);
    }
    page.data = ItemsList;
    return page;
  }

  static toListParam(params, page?: Pagination<any>) {
    let param = {};
    if (params.during) {
      if (params.during.priceMin != null) param['PriceMin'] = params.during.priceMin;
      if (params.during.priceMax != null) param['PriceMax'] = params.during.priceMax;
      if (params.during.stockQtyMin != null) param['StockQtyMin'] = params.during.stockQtyMin;
      if (params.during.stockQtyMax != null) param['StockQtyMax'] = params.during.stockQtyMax;
    }
    if (params.itemName) param['Name'] = params.itemName;
    if (params.categoryGuid && params.categoryGuid !== Constant.ALL) param['CategoryGuid'] = params.categoryGuid;
    if (params.exceptCategoryFlag) param['ExceptCategoryFlag'] = params.exceptCategoryFlag;
    param['IsModifier'] = params.isModifier ? params.isModifier : Constant.ALL ;
    if (page) {
      param['PageSize'] = page.pageSize;
      param['PageIndex'] = page.currPage;
    }
    return param;
  }

  static toItemModel(res): Item {
    let modifiers: Array<Item> = [], modifierGuids: Array<string> = [];
    if (res['Modifiers'] && res['Modifiers'].length) {
      res['Modifiers'].forEach((Modifier) => {
        modifiers.push(new Item({guid: Modifier['ModifierGuid'], itemName: Modifier['Name']}));
        modifierGuids.push(Modifier['ModifierGuid']);
      });
    }

    let modifierGroupGuids: Array<string> = [];
    if (res['ModifierGroups'] && res['ModifierGroups'].length) {
      res['ModifierGroups'].forEach((ModifierGp) => {
        modifierGroupGuids.push(ModifierGp['ModifierGroupGuid']);
      });
    }

    let item: Item = new Item();
    item.guid = res['Guid'];
    item.itemName = res['Name'];
    item.categoryGuid = res['CategoryGuid'];
    item.trackInventory = res['TrackInventory'];
    item.stockQuantity = res['StockQuantity'];
    item.enable = res['Enable'];
    item.displayInMenu = res['DisplayInMenu'];
    item.sku = res['SKU'];
    item.displayOrder = res['DisplayOrder'];
    item.itemDesc = res['Description'];
    item.price = res['Price'];
    item.picture = res['Picture'] ? Constant.IMG_BASE64_PREFIX + res['Picture'] : '';
    item.taxCategoryGuid = res['TaxCategoryGuid'];
    item.taxExempt = res['TaxExempt'];
    item.isModifier = res['IsModifier'];
    item.modifiers = modifiers;
    item.modifierGuids = modifierGuids;
    item.modifierGroupGuids = modifierGroupGuids;
    item.createdDate = DateService.getDateAndTimeString(DateService.utcToLocal(res['CreatedUTC']));
    item.lastUpdatedDate = DateService.getDateAndTimeString(DateService.utcToLocal(res['LastUpdatedUTC']));
    item.createdBy = res['CreatedBy'];
    item.lastUpdatedBy = res['LastUpdatedBy'];
    item.categoryName = res['CategoryName'];
    item.taxCategoryName = res['TaxCategoryName'];
    return item;
  }

  static toItemParam(item: Item) {
    let param = {};
    if (item.guid) param['ItemGuid'] = item.guid;
    if (item.itemName) param['Name'] = item.itemName;
    if (item.price) param['Price'] = item.price;
    if (item.modifierGroupGuids) param['ModifierGroupGuids'] = item.modifierGroupGuids;
    if (item.modifierGuids) param['ModifierGuids'] = item.modifierGuids;
    param['SKU'] = item.sku;
    param['Description'] = item.itemDesc;
    param['StockQuantity'] = item.stockQuantity;
    param['CategoryGuid'] = item.categoryGuid;
    param['TaxCategoryGuid'] = item.taxCategoryGuid;
    // param['DisplayOrder'] = item.displayOrder;
    param['TaxExempt'] = item.taxExempt;
    param['IsModifier'] = item.isModifier;
    param['Enable'] = item.enable;
    param['DisplayInMenu'] = item.displayInMenu;
    // param['TrackInventory'] = item.trackInventory;
    param['Picture'] = item.picture.replace(/^data:image\/.*;base64,/, '');
    return param;
  }

  getItemsList(params, page: Pagination<any>): Promise<Response<Pagination<Item>>> {
    let response: Response<Pagination<Item>> = new Response<Pagination<Item>>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Item/GetItemsList', {
        data: ItemService.toListParam(params, page)
      }).subscribe(res => {
        response.data = ItemService.toPageItem(res);
        resolve(response);
      }, error => reject(error));
    });
  }

  getItemDetails(guid: string): Promise<Response<Item>> {
    let response: Response<Item> = new Response<Item>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Item/GetItemDetails', {restful: {'ItemGuid': guid}}).subscribe(res => {
        response.data = ItemService.toItemModel(res);
        resolve(response);
      }, error => reject(error));
    });
  }

  addItem(item: Item): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Item/AddItem', {
        data: ItemService.toItemParam(item)
      }).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    });

  }

  EditItem(item: Item): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((reslove, reject) => {
      this.http.post('/Merchant/Item/EditItem', {
        data: ItemService.toItemParam(item)
      }).subscribe(res => {
        response.data = res;
        reslove(response);
      }, error => reject(error));
    });
  }

  deleteItem(guid: string): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Item/DeleteItem', {
        data: {
          'ItemGuid': guid
        }
      }).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    });
  }

  deleteItemCategories(itemGuids: Array<string>): Promise<Response<Array<Item>>> {
    let response: Response<Array<Item>> = new Response<Array<Item>>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Item/DeleteItemCategories', {
        data: {
          'ItemGuids': itemGuids
        }
      }).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    });
  }
  addCategoryToItems(categoryGuid: string, itemGuids: Array<string>): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Item/AddCategoryToItems', {
        data: {
          'CategoryGuid': categoryGuid,
          'ItemGuids': itemGuids
        }
      }).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    });
  }


  updateItemsList(pageSize: number, lastUpdateUTC: string): Promise<Response<Item>> {
    let response: Response<Item> = new Response<Item>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Item/UpdateItemsList', {
        data: {
          PageSize: pageSize ? pageSize : 10,
          LastUpdateUTC: lastUpdateUTC
        }
      }).subscribe(res => {
        response.data = res;
        resolve(response);
      }, error => reject(error));
    });
  }

}


