import { CartProduct } from "./cartProduct";
export class HardwareOrder{  
  orderId: number;
  products: Array<CartProduct>;
  shippingAddress: string;
  billingToName: string;
  orderDate: string;
  payCardLastFour: string;
  payCardType: string;
  payId: string;
  totalPrice: number;
  shipStatus: string;
  shippingMethod: string;
  subtotal: number; 
  shipping: number;
  tax: number;
  companyName: string;
  recipientName: string;
  shippingToName: string;
  email: string;
  phoneNumber: string;
  paymentMethod: string;
  totalQuantity: number;
  invoiceNum: string;
  purchaseToken: string;
  productNameList: String[]

  constructor(options: {
    orderId?: number,
    products?: Array<CartProduct>,
    shippingAddress?: string,
    billingToName?:string,
    orderDate?: string,
    payCardLastFour?: string,
    payCardType?: string,
    payId?: string,
    totalPrice?: number,
    shipStatus?: string,
    shippingMethod?: string;
    subtotal?: number; 
    shipping?: number;
    tax?: number;
    companyName?: string;
    recipientName?: string;
    shippingToName?:string;
    email?: string;
    phoneNumber?: string;
    paymentMethod?: string;
    totalQuantity?: number;
    invoiceNum?:string;
    purchaseToken?:string;
    productNameList?: String[]
  } = {}) {
    this.orderId  = options.orderId  || 0;
    this.products = options.products || [];
    this.shippingAddress = options.shippingAddress || '';
    this.billingToName = options.billingToName || '';
    this.orderDate = options.orderDate || '';
    this.payCardLastFour = options.payCardLastFour || '';
    this.payCardType = options.payCardType || '';
    this.payId = options.payId || '';
    this.totalPrice = options.totalPrice || 0;
    this.shipStatus = options.shipStatus || '';
    this.shippingMethod  = options.shippingMethod  || '';
    this.subtotal = options.subtotal || 0;
    this.shipping = options.shipping || 0;
    this.tax = options.tax || 0;
    this.companyName = options.companyName || ''
    this.recipientName  = options.recipientName  || '';
    this.shippingToName = options.shippingToName || '';
    this.email = options.email || '';
    this.phoneNumber = options.phoneNumber || '';
    this.paymentMethod = options.paymentMethod || '';
    this.totalQuantity = options.totalQuantity || 0;
    this.invoiceNum = options.invoiceNum || '';
    this.purchaseToken = options.purchaseToken || '';
    this.productNameList = options.productNameList || [];
  }
}
