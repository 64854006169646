export class Constant {
  public static ui_pagination_pre: number = 5;
  public static ui_pagination_total: number = 10;
  public static ui_pagination_pageSize: number = 10;
  public static ui_pagination_pageSizeList: Array<number> = [5, 10, 20, 30, 50, 100];

  public static api_success_code: string = '000000';

  public static ALL: string = 'ALL';
  public static ACTIVE: string = 'Active';
  public static INACTIVE: string = 'Inactive';
  public static INACTIVE_KEY: number = 0;
  public static ACTIVE_KEY: number = 1;
  public static FAILURE_KEY: number = 2;
  public static SUCCESS: string = 'SUCCESS';
  public static FAILURE: string = 'FAILURE';

  public static TODAY: string = 'Today';
  public static LAST_WEEK: string = 'Last Week';
  public static LAST_MONTH: string = 'Last Month';

  public static DEFAULT_PASSCODE_LENGTH = 6;
  public static REFRESH_TOKEN_ERROR: string = 'REFRESH_TOKEN_ERROR';
  public static IMG_BASE64_PREFIX: string = 'data:image/png;base64,';

  public static ALWAYS_SHOW: string = 'ALWAYS_SHOW';

  public static COUNTRY_LIST: Array<string> = ['US', 'CA'];

  public static TOKEN_INVALID_REQUEST: string = 'Invalid Request';  // 重复登录
  public static TOKEN_INVALID_JWT: string = 'Invalid Jwt Token';
  public static TOKEN_MISSING_JWT: string = 'Missing Jwt Token';
  public static TOKEN_INVALID_SIGNATURE: string = 'Invalid Signature';
  public static TOKEN_ACCESS_DENIED: string = 'Access Denied';

  public static INT_MAX: number = 200;
}
