import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InitiateService} from './services/login.service';
import {CustomService} from './services/custom.service';
import {Title} from '@angular/platform-browser';
import {version} from 'package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'Poslink Lite';
  showloading = true;

  constructor(translate: TranslateService, private initiateService: InitiateService, private TitleService: Title) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');

    this.initStyle();
  }

  initStyle() {
    this.initiateService.getConfiguredDomain().then((res) => {
      CustomService.setUrlPrefix(res.data);
      this.getTitleInfo(res.data);
      this.appendStyle(res.data);
    });
  }

  getTitleInfo(name) {
    if (name === 'boa') {
      this.TitleService.setTitle('Bank of America Mobile Point of Sale');
    } else {
      this.TitleService.setTitle('Poslink Lite');
    }
  }

  appendStyle(path) {
    let appends = [
      {
        tag: 'link',
        href: 'assets/themes/{{path}}/poslinkicon.png',
        rel: 'icon',
        type: 'image/x-icon'
      }, {
        tag: 'link',
        href: 'assets/themes/{{path}}/style.css?v=' + version,
        rel: 'stylesheet',
        type: 'text/css'
      }
    ];

    let head = document.getElementsByTagName('head')[0];
    let child = head.firstChild;

    Object.keys(appends).forEach((i) => {
      let el = appends[i];
      let link = document.createElement('link');
      link.href = el.href.replace('{{path}}', path);
      link.rel = el.rel;
      link.type = el.type;
      head.insertBefore(link, child);
    });

    let title = CustomService.title();
    if (title) {
      let titleStyle = document.createElement('style');
      titleStyle.innerHTML = `.up-theme-title:before{content: "${title} ";}`;
      head.insertBefore(titleStyle, child);
    }

    setTimeout(() => {
      this.showloading = false;
    }, 500);
  }
}
