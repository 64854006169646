export class Product {
  productId: number;
  productName: string;
  productPrice: number;
  productImgURL: string;
  productDescription: string

  constructor(options: {
    productId?: number,
    productName?: string,
    productPrice?: number,
    productImgURL?: string,
    productDescription?: string
  } = {}) {
    this.productId = options.productId || 0;
    this.productName = options.productName || '';
    this.productPrice = options.productPrice || 0;
    this.productImgURL = options.productImgURL || '';
    this.productDescription = options.productDescription || '';
  }
}