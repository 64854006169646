import {Injectable} from '@angular/core';
import {DialogComponent} from '../components/dialog/dialog.component';
import {Modal, ModalService} from './modal.service';
import {Response} from '../models/response';
import {TranslateService} from '@ngx-translate/core';
import {Constant} from '../constants/constant';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  apiErrors = null;
  lang = null;

  constructor(private modalService: ModalService, private translate: TranslateService) {
  }

  create(options): Modal {
    return this.modalService.create(DialogComponent, options);
  }

  modal(template, title = '', cssClass = '', backdrop:boolean|string = 'static'): Modal {
    const modal = this.modalService.create(DialogComponent, {title, template, cssClass, });
    // Click on the blank space and the modal hide
    if(backdrop == true) {
      modal.$modalEl.modal({backdrop: backdrop})
    }
    return modal
  }

  success(msg = 'COMMON.SAVE_SUCCESS'): Modal {
    return this.alert(msg, 'COMMON.SUCCESS');
  }

  alertWithoutMsg(msg, title = 'COMMON.ATTENTION', time = 1000): void {
      let alert = this.create({
        title,
        msg
      });
      alert.show();
      setTimeout(() => {
        alert.hide();
      }, time);
  }

  alert(msg, title = 'COMMON.ATTENTION'): Modal {
    let alert = this.create({
      title,
      msg,
      buttons: [
        {
          text: 'Ok',
          close: true,
        }
      ]
    });
    alert.show();
    return alert;
  }

  delayHiden(msg, title = '', time): Modal {
    let options = {
      title: 'hello',
      msg: 'nihao',
      type: 'delay',
      delayTime: time ? time : 5,
      buttons: [
        {
          text: 'Ok',
          close: true,
          handler: () => {
            // console.log('Confirm Cancel');
          }
        }
      ]
    };
    return this.create(options);
  }

  confirm(title, msg, yes, no?): Modal {
    let options = {
      title,
      msg,
      staticBackdrop: true,
      buttons: [
        {
          text: 'No',
          close: true,
          cssClass: 'btn-outline-dark',
          handler: () => {
            if (no) {
              no();
            }
          }
        },
        {
          text: 'Yes',
          close: true,
          handler: () => {
            yes();
          }
        }
      ]
    };
    let confirm = this.create(options);
    confirm.show();
    return confirm;
  }

  confirmWithCustomText(title, msg, yes, no?, yesText: string = 'Yes', noText: string = 'No', yes_btn_class: string='', no_btn_class: string='btn-outline-dark'): Modal {
    let options = {
      title,
      msg,
      staticBackdrop: true,
      buttons: [
        {
          text: noText,
          close: true,
          cssClass: no_btn_class,
          handler: () => {
            if (no) {
              no();
            }
          }
        },
        {
          text: yesText,
          close: true,
          cssClass: yes_btn_class,
          handler: () => {
            yes();
          }
        }
      ]
    };
    let confirm = this.create(options);
    confirm.show();
    return confirm;
  }

  notification(title: string, msg: string, yes: Function, btnText: string = 'Yes', closable: boolean = true): Modal {
    let options = {
      title,
      msg,
      staticBackdrop: true,
      buttons: [
        {
          text: btnText,
          close: closable,
          handler: () => {
            yes();
          }
        }
      ]
    };
    let notification = this.create(options);
    notification.show();
    return notification;
  }

  destory(modal: Modal) {
    this.modalService.destory(modal);
  }

  error_msg(res: Response<any>): string {
    let msg;
    if (res.resultMsg || res.ResultMsg) msg = res.resultMsg || res.ResultMsg;
    else if (res.httpError) {
      if (res.httpError.error && typeof (res.httpError.error) === 'string') msg = res.httpError.error;
      else if (res.httpError.error && res.httpError.error.message) msg = res.httpError.error.message
      else if (res.httpError.message && typeof (res.httpError.message) === 'string') msg = res.httpError.message;
      else msg = `Http Error: ${res.httpError.status} - ${res.httpError.statusText}`;
    } else if (res instanceof TypeError) msg = res.toString();
    else msg = this.translate.get('API_ERRORS.UNKNOWN')['value'];
    return msg;
  }

  error(res: Response<any>, error_msg: string = 'COMMON.ATTENTION'): Modal {
    let alert;
    if (res.flag && res.flag === Constant.REFRESH_TOKEN_ERROR) {
      return alert;
    } else if (res.httpError && res.httpError['flag'] && res.httpError['flag'] === Constant.REFRESH_TOKEN_ERROR)
      return alert;
    else if(res.httpError && res.httpError.status == 401){
      return;
    }
    else {
      alert = this.alert(this.error_msg(res), error_msg);
    }
    return alert;
  }


  private getErrorMsg(resultMsg, callback) {
    let translate = (apiErrors) => {
      let msg = apiErrors[resultMsg];
      if (!msg) {
        msg = `
          ${apiErrors['UNKNOWN']}<br>
          ${this.translate.get('API_ERRORS.ERROR_CODE', {error_code: resultMsg})['value']}
        `;
      }
      callback(msg);
    };

    if (this.apiErrors && this.lang === this.translate.currentLang) {
      translate(this.apiErrors);
      return;
    }
    this.lang = this.translate.currentLang;
    this.translate.get('API_ERRORS').subscribe(res => {
      this.apiErrors = res;
      translate(this.apiErrors);
    });
  }


}
