export class VerfifyAssdressResp {
  address: string
  address2: string
  city: string
  state: string
  zip4: string
  zip5: string
  constructor(option: {
    address?: string,
    address2?:string,
    city?: string,
    state?: string,
    zip4?: string,
    zip5?: string,
  }={}){
    this.address = option.address || ''
    this.address2 = option.address2 || ''
    this.city = option.city || ''
    this.state = option.state || ''
    this.zip4 = option.zip4 || ''
    this.zip5 = option.zip5 || ''
  }
}