import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[inputTrim]'
})
export class InputTrimDirective {

  constructor(private el: ElementRef, private ngControl: NgControl) {

  }

  @HostListener('blur')
  onBlur() {
    const trimmedValue = this.el.nativeElement.value.trim()
    this.ngControl.control.setValue(trimmedValue)
  }

}
