import {Injectable} from '@angular/core';
import {Pagination} from '../models/pagination';
import {Response} from '../models/response';
import {User} from '../models/user';
import {Role} from '../models/role';
import {Constant} from '../constants/constant';
import {HttpService} from './http.service';
import {EncryptService} from '../utils/encrypt.service';
import {DateService} from '../utils/date.service';
import { HttpHeaders } from '@angular/common/http';
import { stringify } from 'querystring';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpService) {
  }

  static toListParam(userName, role, page: Pagination<any>) {
    let param = {};
    if (userName) param['UserName'] = userName;
    if (role !== Constant.ALL) param['Role'] = role;
    param['PageSize'] = page.pageSize;
    param['PageIndex'] = page.currPage;
    return param;
  }

  static toPageUser(res): Pagination<User> {
    let page: Pagination<User> = new Pagination<User>(res);

    let users: Array<User> = [];
    for (let mUser of res['MerchantUserInfo']) {
      let user: User = new User();
      user.merchantUserKey = mUser['MerchantUserKey'];
      user.userName = mUser['UserName'];
      user.email = mUser['Email'];
      user.role = mUser['Role'];
      user.status = mUser['Status'] === Constant.ACTIVE_KEY ? Constant.ACTIVE : Constant.INACTIVE;
      user.lastLogon = DateService.getDateAndTimeString(DateService.utcToLocalSpe(mUser['LastLogon']));
      user.registerFlag = Boolean(mUser['RegisterFlag']);
      user.editFlag = mUser['EditFlag'];
      users.push(user);
    }
    page.data = users;
    return page;
  }

  static toUserParam(user: User) {
    let param = {};
    if (user.email) param['Email'] = user.email;
    if (user.role) param['Role'] = user.role;
    if (user.firstName) param['FirstName'] = user.firstName;
    if (user.lastName) param['LastName'] = user.lastName;
    if (user.userName) param['UserName'] = user.userName;
    if (user.merchantUserKey) param['MerchantUserKey'] = user.merchantUserKey;
    if (user.phone) param['Phone'] = user.phone;
    if (user.mobile) param['Mobile'] = user.mobile;
    if (user.streetAddress1) param['StreetAddress1'] = user.streetAddress1;
    if (user.streetAddress2) param['StreetAddress2'] = user.streetAddress2;
    if (user.city) param['City'] = user.city;
    if (user.state) param['State'] = user.state;
    if (user.status) param['Status'] = user.status === Constant.ACTIVE ? Constant.ACTIVE_KEY : Constant.INACTIVE_KEY;
    if (user.country) param['Country'] = user.country;
    if (user.zip) param['ZIPCode'] = user.zip;
    return param;
  }

  static toUserModel(res): User {
    let user: User = new User();

    user.userName = res['UserName'];
    user.firstName = res['FirstName'];
    user.lastName = res['LastName'];
    user.merchantName = res['MerchantName'];
    user.email = res['Email'];
    user.phone = res['Phone'];
    user.mobile = res['Mobile'];
    user.streetAddress1 = res['StreetAddress1'];
    user.streetAddress2 = res['StreetAddress2'];
    user.city = res['City'];
    user.state = res['State'];
    user.status = res['Status'] === Constant.ACTIVE_KEY ? Constant.ACTIVE : Constant.INACTIVE;
    user.role = res['Role'];
    user.country = res['Country'];
    user.zip = res['ZIPCode'];
    user.registerFlag = Boolean(res['RegisterFlag']);
    user.isPrimaryUser = res['IsPrimaryUser'];
    user.editFlag = res['EditFlag'];
    return user;
  }


  getMerchantUserList(userName, role, page: Pagination<any>): Promise<Response<Pagination<User>>> {
    let response: Response<Pagination<User>> = new Response<Pagination<User>>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/User/GetMerchantUserList', {
        data: UserService.toListParam(userName, role, page)
      }).subscribe(res => {
        response.data = UserService.toPageUser(res);
        resolve(response);
      }, error => reject(error));
    });
  }

  getMerchantUserDetails(merchantUserKey: string): Promise<Response<User>> {
    let response: Response<User> = new Response<User>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/User/MerchantUserDetails', {
        restful: {
          'MerchantUserKey': merchantUserKey
        }
      }).subscribe(res => {
        let user = UserService.toUserModel(res['MerchantDetails']);
        user.merchantUserKey = merchantUserKey;
        response.data = user;
        resolve(response);
      }, error => reject(error));
    });
  }

  addMerchantUser(user: User): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/User/AddMerchantUser', {
        data: UserService.toUserParam(user)
      }).subscribe(res => {
        response.data = res['MerchantUserkey'];
        resolve(response);
      }, error => reject(error));
    });
  }

  editMerchantUser(user: User): Promise<Response<string>> {  // return MerchantUserKey
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/User/EditMerchantUser', {
        data: UserService.toUserParam(user)
      }).subscribe(res => {
        response.data = res['MerchantUserkey'];
        resolve(response);
      }, error => reject(error));
    });
  }

  getMerchantUserSalt(merchantUserKey: string): Promise<Response<string>> {
    let response: Response<string> = new Response<string>();
    return new Promise((resolve, reject) => {
      this.http.get('/Merchant/Security/GetMerchantUserSalt', {
        restful: {'MerchantUserKey': merchantUserKey}
      }).subscribe(res => {
        response.data = res['ForeEndSalt'];
        resolve(response);
      }, error => reject(error));
    });
  }

  resetPassword(merchantUserKey): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Security/ResetPassword', {
        data: {
          'MerchantUserKey': merchantUserKey
        }
      }).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }

  resetPasscode(merchantUserKey): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.post('/Merchant/Security/ResetPasscode', {
        data: {
          'MerchantUserKey': merchantUserKey
        }
      }).subscribe(res => {
        response.data = true;
        resolve(response);
      }, error => reject(error));
    });
  }

  createUsers(params): Promise<Response<boolean>> {
    let response: Response<boolean> = new Response<boolean>();
    return new Promise((resolve, reject) => {
      this.http.post('/support/Users', {
        data: stringify(params),
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      }).subscribe(res => {
        if(res.resultCode != '000' && res.resultCode != '0000') {
          reject(res)
        }
        response.data = res;
        resolve(response);
      }, error => {
        reject(error)});
    });
  }

}
