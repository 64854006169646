import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import {LoginService} from '../../services/login.service';
import {AuthService} from '../../services/auth.service';
import {Subscription} from 'rxjs';
import {ProfileService} from '../../services/profile.service';
import {DialogService} from '../../utils/dialog.service';
import {Constant} from '../../constants/constant';
import {User} from '../../models/user';
import {MerchantService} from '../../services/merchant.service';
import {LoadingService} from '../../utils/loading.service';
import {Router} from '@angular/router';
import {FindFirstPage} from '../../constants/menu';
import {TranslateService} from '@ngx-translate/core';
import {Merchant} from '../../models/merchant';
import {Pagination} from '../../models/pagination';

import { Location } from '@angular/common';
import { Modal } from 'src/app/utils/modal.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Output() Change = new EventEmitter();
  isHidden: boolean = false;
  user: User = new User();
  role: string = ''
  merchants: Array<{ key: string, address1: string, address2: string }> = [];
  merchantName: string;
  merchantIndex: number;
  modal: Modal;

  profileChange: Subscription;
  merchantChange: Subscription;
  pagination: Pagination<Merchant> = new Pagination<Merchant>();

  constructor(private el: ElementRef, private dialog: DialogService, private merchantService: MerchantService,
              private loginService: LoginService, public authService: AuthService, private profileService: ProfileService,
              private loading: LoadingService, private router: Router, private translate: TranslateService,
              private location: Location) {
  }

  msgNum = 1;

  ngOnInit() {
    this.init();
    this.subscribeProfileChange();
    this.subscribeMerchantChange();
    this.listen();
  }

  ngOnDestroy() {
    this.profileChange.unsubscribe();
    this.merchantChange.unsubscribe();
  }

  listen() {
    // 定义监听事件
    let divEle = this.el.nativeElement.querySelector('.dropdown-menu-merchant');
    divEle.addEventListener('scroll',this.scroll,true)
  }
  // 滚动时请求需要加载的数据
  scroll=(e)=>{
    if(this.pagination.currPage == this.pagination.totalPage)this.removeListen(); 
    if (e.target.scrollTop + e.target.clientHeight == e.target.scrollHeight) {
      this.pagination.currPage ++;
      this.getMerchantList(); 
    }
  }

  removeListen(){
    let divEle = this.el.nativeElement.querySelector('.dropdown-menu-merchant');
    divEle.removeEventListener('scroll', this.scroll, true);
  }

  init() {
    this.user = this.authService.getProfile();
    this.role = this.authService.getRole();
    this.merchantName = this.user.firstName + this.user.lastName;
    console.log(this.user, 'user',this.merchantName, this.role)
  }

  subscribeProfileChange() {
    this.profileChange = this.profileService.get().subscribe(profile => {
      this.user = profile;
    });
  }

  subscribeMerchantChange() {
    this.merchantChange = this.merchantService.get().subscribe(merchant => {  
      this.merchantName = merchant[0].key;  
      this.merchants.splice(this.merchantIndex,1,merchant[0]) 
    });
  }

  logout() {
    this.loginService.logoutAlert();
  }

  createAccount() {
    this.router.navigate(['/sign-up']);
  }

  openProfile(template: TemplateRef<any>) {
    this.modal = this.dialog.modal(template, null, 'cart-dialog');
    this.modal.show();
  }

  toggle() {
    this.isHidden = !this.isHidden;
    this.Change.emit(this.isHidden);
  }

  changeMerchant(merchant: string) {
    this.dialog.confirm('PROFILE.CHANGE_MERCHANT', this.translate.get('PROFILE.CHANGE_MERCHANT_TIP', {merchant})['value'], () => {
      this.loading.show();
      this.merchantService.change(merchant).then((res) => {
        let router = FindFirstPage(res.data.authority);
        location.href = location.href.replace(this.router.url, '') + router;
      }).catch((error) => {
        this.dialog.error(error);
      }).finally(() => {
        this.loading.hide();
      });
    });
  }

  getMerchantList() {
    // this.loading.show();
    // this.pagination.pageSize = Constant.INT_MAX;
    // this.merchantService.getMerchantList('', Constant.ACTIVE, this.pagination).then((res) => {
    //   this.pagination.totalPage = res.data.totalPage;
    //   let merchants: Array<{ key: string, address1: string, address2: string }> = [];
    //   res.data.data.forEach((item,index) =>{
    //     merchants.push({
    //       key: item.merchantName,
    //       address1: item.streetAddress1,
    //       address2: item.streetAddress2
    //     });
    //     if(this.merchantName == item.merchantName) this.merchantIndex = index;          
    //   });
    //  if(this.merchants){
    //     this.merchants = this.merchants.concat(merchants)
    //   }else{
    //     this.merchants = merchants;
    //   }; 
    // }).catch(res => this.dialog.error(res)).finally(() => {
    //   this.loading.hide();
    // });
  }

  clickTitle(t, index: number) {
    let path = '';
    for(let i = 0; i <= index + 1; i++) {
      if (i > 0) {
        path = path + '/' + this.location.path().split('/')[i]

      }
    }
    // if it is not click first menu or last menu
    if(index != 0 && index != this.location.path().split('/').length - 2) {
      this.router.navigate([path])
    }
  }
}
