import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderComponent } from './header/header.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { DialogComponent } from './dialog/dialog.component';
import { PaginationComponent } from './pagination/pagination.component';
import { LoadingComponent } from './loading/loading.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ChooseModifierComponent } from './choose-modifier/choose-modifier.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { PasswordRulesComponent } from './password-rules/password-rules.component';
import { DirectivesModule } from '../directives/directives.module';
import { ValidatorsModule } from '../validtors/validators.module';
import { PopItemComponent } from './pop-item/pop-item.component';
import { VerificationBoxComponent } from './verification-box/verification-box.component';
import { ChangeEmailComponent } from './change-email/change-email.component';

import { DynamicFieldDirective } from './dynamic-form/dynamic-field/dynamic-field.directive';
import { FormInputComponent } from './dynamic-form/form-input/form-input.component';
import { FormSelectComponent } from './dynamic-form/form-select/form-select.component';
import { FormCheckboxGroupComponent } from './dynamic-form/form-checkbox-group/form-checkbox-group.component';
import { FormSwicthComponent } from './dynamic-form/form-switch/form-switch.component';
import { TransactionComponent } from './transaction/transaction.component';
import { FooterComponent } from './footer/footer.component';
import { SimplePaginationComponent } from './simple-pagination/simple-pagination.component';
import { MessageComponent } from './message/message.component';

import { ProfileDialogComponent } from './profile-dialog/profile-dialog';

import { ClickOutsideModule } from 'ng-click-outside';


@NgModule({
  declarations: [
    LeftMenuComponent,
    HeaderComponent,
    ProfileDialogComponent,
    FooterComponent,
    DialogComponent,
    PaginationComponent,
    LoadingComponent,
    DropdownComponent,
    ChooseModifierComponent,
    CheckboxGroupComponent,
    PasswordRulesComponent,
    ChangeEmailComponent,
    TransactionComponent,
    PopItemComponent,
    VerificationBoxComponent,

    DynamicFieldDirective,
    FormInputComponent,
    FormSelectComponent,
    FormCheckboxGroupComponent,
    FormSwicthComponent,
    SimplePaginationComponent,
    MessageComponent
  ],
  imports: [
    DirectivesModule,
    RouterModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    NgbPopoverModule,
    ReactiveFormsModule,
    ValidatorsModule,
    ClickOutsideModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LeftMenuComponent,
    PaginationComponent,
    LoadingComponent,
    DropdownComponent,
    CheckboxGroupComponent,
    PasswordRulesComponent,
    PopItemComponent,
    VerificationBoxComponent,
    ChangeEmailComponent,
    TransactionComponent,
    DynamicFieldDirective,
    ChooseModifierComponent,
    SimplePaginationComponent,
    MessageComponent,
    ProfileDialogComponent
  ],
  entryComponents: [
    DialogComponent,
    PopItemComponent,
    FormInputComponent,
    FormSelectComponent,
    FormCheckboxGroupComponent,
    FormSwicthComponent
  ]
})
export class ComponentsModule {
}
