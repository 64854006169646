import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/utils/dialog.service';
import { Modal } from 'src/app/utils/modal.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  constructor(private dialog: DialogService,) { }
  @ViewChild('messageTemplate',{static: false}) messageTemplate
  @Input() duration: number
  @Input() title: string = ''
  @Input() message: string = ''
  ngOnInit() {
  }
  timer
  modal: Modal
  showMessage({title = '', message = '', duration = 1500}) {
    this.title = title
    return new Promise((resolve, reject) => {
      try {
        this.message = message
        this.duration = duration
        this.modal = this.dialog.modal(this.messageTemplate, this.title)
        this.modal.show()
        this.timer = setTimeout(() => {
          this.modal.hide()
          this.timer && clearTimeout(this.timer)
          resolve(null)
        }, this.duration)
      } catch (error) {
        reject()
      }
    })
  }
  
  destory() {
    this.timer && clearTimeout(this.timer)
  }
  
}
