import {RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';

export class ReuseStrategyService implements RouteReuseStrategy {

  // constructor() { }

  _cacheRouters: { [key: string]: any } = {};
  from = '';
  to = '';
  storeRoute = [{from: 'payments/details', to: 'payments'}, {
    from: 'voids/details',
    to: 'voids'
  },
  // {from: 'all/orderdetail', to: 'all'},
  // {from: 'ordered/orderdetail', to: 'ordered'},
  // {from: 'cancel/orderdetail', to: 'cancel'},
  // {from: 'return/orderdetail', to: 'return'},
  {from: 'voids/refunds', to: 'refunds'}, {from: 'pay', to: 'checkout'}, {from: 'product-detail', to: 'hardware'}];

  shouldDetach(route: ActivatedRouteSnapshot): boolean { // 离开时是否缓存
    return (!!route.data.keepAlive);
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    // 按path作为key存储路由快照&组件当前实例对象
    // path等同RouterModule.forRoot中的配置
    this._cacheRouters[route.routeConfig.path] = {
      snapshot: route,
      handle
    };
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {  // 进入时候是否存缓存中获取
    if (!route.routeConfig || !this._cacheRouters[route.routeConfig.path]) return false;

    let f: boolean = false;
    for (let r of this.storeRoute) {
      if (this.from === r.from && this.to === r.to) {
        f = true;
        break;
      }
    }
    return f;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle { // 从缓存中获取快照，若无则返回null
    if (!route.routeConfig || route.routeConfig.loadChildren || !this._cacheRouters[route.routeConfig.path]) return null;
    let handle = this._cacheRouters[route.routeConfig.path].handle;
    return handle;
  }

  shouldReuseRoute(from: ActivatedRouteSnapshot, to: ActivatedRouteSnapshot): boolean {
    if (from.routeConfig) this.from = this.getPath(from);
    if (to.routeConfig) this.to = this.getPath(to);
    return from.routeConfig === to.routeConfig;
  }

  private getPath(route: ActivatedRouteSnapshot): string {
    return (route.routeConfig !== null && route.routeConfig.path !== null) ? route.routeConfig.path : '';
  }

}
