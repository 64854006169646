import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CookieService} from 'ngx-cookie-service';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './pages/login/login.component';
import {TwoFactorLoginComponent} from './pages/two-factor-login/two-factor-login.component';
import {ComponentsModule} from './components/components.module';
import {ForgetPasswordComponent} from './pages/forget-password/forget-password.component';
import {InitialComponent} from './pages/initial/initial.component';

import {ModalService} from './utils/modal.service';
import {LoadingService} from './utils/loading.service';

import {InterceptorService} from './services/interceptor.service';

import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {ValidatorsModule} from './validtors/validators.module';
import {DefaultUrlSerializer, RouteReuseStrategy, UrlSerializer, UrlTree} from '@angular/router';
import {ReuseStrategyService} from './services/reuse-strategy.service';
import {AnonyReceiptComponent} from './pages/anony-receipt/anony-receipt.component';
import {ResetComponent} from './pages/reset/reset.component';
import {DirectivesModule} from './directives/directives.module';
import {version} from 'package.json';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { ValidateEmailComponent } from './pages/validate-email/validate-email.component';
import { PaymentResultComponent } from './pages/payment-result/payment-result.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + version);
}

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {

  parse(url: string): UrlTree {
    let arr = url.split('?');
    const receiptUrl = '/receipt/';
    if (arr[0].toLocaleLowerCase().startsWith(receiptUrl)) {
      const suffix = arr[0].substr(receiptUrl.length);
      arr[0] = receiptUrl + suffix;
    } else arr[0] = arr[0].toLocaleLowerCase();
    return super.parse(arr.join('?'));
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TwoFactorLoginComponent,
    ForgetPasswordComponent,
    SignUpComponent,
    ResetPasswordComponent,
    InitialComponent,
    AnonyReceiptComponent,
    ResetComponent,
    SignUpComponent,
    ValidateEmailComponent,
    PaymentResultComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    NgbPopoverModule,
    ValidatorsModule,
    DirectivesModule,
    MarkdownModule.forRoot({ loader: HttpClient }), 
  ],
  providers: [
    ModalService,
    LoadingService,
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    {provide: RouteReuseStrategy, useClass: ReuseStrategyService},
    {provide: UrlSerializer, useClass: LowerCaseUrlSerializer},
    CookieService,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
