import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Constant} from '../../constants/constant';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

  constructor() {
  }

  @Input() pageSize;
  @Output() pageSizeChange = new EventEmitter();

  @Input() totalPage: number;

  @Input() currPage: number = 5;
  @Output() currPageChange = new EventEmitter();

  @Output() private onChangePage = new EventEmitter();

  showPages: Array<number> = [];
  display: { pre: number, total: number } = {
    pre: Constant.ui_pagination_pre,
    total: Constant.ui_pagination_total - 1
  };
  pageSizeList: Array<number> = Constant.ui_pagination_pageSizeList;

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.pagination();
  }

  _pageSizeChange = () => {
    this.pageSizeChange.emit(this.pageSize);
    this.currPageChange.emit(1);
    this.onChangePage.emit();
  };

  _currPageChange = (page) => {
    this.currPageChange.emit(page);
    this.onChangePage.emit();
  };

  pagination = () => {
    let page = this.currPage;
    let display = this.display;
    let totalPages = this.totalPage;

    let min = page - display.pre;
    if (min <= 0) min = 1;

    let max = min + display.total;
    if (max > totalPages) {
      max = totalPages;
      min = max - display.total;
      if (min <= 0) min = 1;
    }

    let showPages: Array<number> = [];
    for (let i = min; i <= max; i++) showPages.push(i);

    this.showPages = showPages;
  };
}
