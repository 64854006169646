import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoadingService} from '../../utils/loading.service';
import {Observable, Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {

  isShowBg: boolean = false;
  counts: number = 0;
  content: string;
  loadingChange: Subscription;

  constructor(private loading: LoadingService) {
  }

  ngOnInit() {
    this.loadingChange = this.loading.get().subscribe(loading => {
      if (loading.show) this.show(loading.msg);
      else this.hide();
    });
  }

  ngOnDestroy(): void {
    this.loadingChange.unsubscribe();
  }

  show(msg) {
    this.counts++;
    this.content = msg ? msg : '';
    if (this.counts === 1) setTimeout(() => {
      if (this.counts) this.isShowBg = true;
    }, 50);
  }

  hide() {
    this.counts--;
    if (this.counts === 0) this.isShowBg = false;
  }
}
