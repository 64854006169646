import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SignUpService } from "../../services/sign-up.service";
import { LoginService } from "../../services/login.service";
import { DialogService } from "../../utils/dialog.service";
import { LoadingService } from "../../utils/loading.service";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { FindFirstPage } from "../../constants/menu";
import { ValidatorsService } from "../../validtors/validators.service";
import { UserService } from "src/app/services/user.service";
import {Location} from '@angular/common';

import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-sign-up',
  host: {class: 'up-login-container'},
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  email: string;
  firstName: string;
  lasttName: string;
  merchantName: string;

  submitted = false;
  signUpForm: FormGroup;

  error_msg: string;

  constructor(
    private router: Router,
    private loading: LoadingService,
    private dialog: DialogService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private location: Location,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.signUpForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]],
      firstName: ['', [Validators.required, Validators.maxLength(32)]],
      lastName: ['', [Validators.required,Validators.maxLength(32)]],
    });
  }

  back() {
    this.location.back();
  }

  async signUp() {
    this.submitted = true;
    if (this.signUpForm.invalid) return;
  }
  titleList = [
    {
      title: 'Get start quickly',
      detail: 'Integrate with developer-friendly APIs or choose low-code or pre-build solutions.'
    },
    {
      title: 'Support any business model',
      detail: 'E-commerce, subscription, SaaS platforms, marketplaces, and more -all within a unified platform.'
    },
    {
      title: 'Join millions of businesses',
      detail: 'Poslink Lite is trusted by ambitious startups and enterprises of every size.'
    }
  ]

  signInfoList = [
    {
      field: 'email',
      placeHolder: 'LOGIN.ENTER_EMAIL',
      innerText: 'USER.EMAIL',
      type: 'text',
      status: false
    },
    {
      field: 'firstName',
      placeHolder: 'LOGIN.ENTER_FIRSTNAME',
      innerText: 'USER.FIRST_NAME',
      type: 'text',
      status: false
    },
    {
      field: 'lastName',
      placeHolder: 'LOGIN.ENTER_LASTNAME',
      innerText: 'USER.LAST_NAME',
      type: 'text',
      status: false
    },
    {
      field: 'password',
      placeHolder: 'LOGIN.ENTER_PASSWORD',
      innerText: 'PROFILE.PASSWORD',
      type: 'password',
      status: false
    }
  ]

  selections = ['Support Specialists', 'Admin Specialists']
  userRole = 'Support Specialists'

  showPsw = false;

  clickShowPsw(field: string | number) {
    this.showPsw = !this.showPsw;
    
    const infoItem = this.signInfoList.find(item => item.field === field)
    infoItem.type = this.showPsw ? 'text' : 'password';
  }

  onRoleChange(e) {
   this.userRole = e;
  }

  async signUpClick() {
    // validate every item
    this.signInfoList.forEach(item => {
      this.checkFormValidate(item.field)
    })
    if(this.signUpForm.invalid) {
      return
    }

    this.loading.show();

    const data = {
      'role': this.userRole == 'Support Specialists' ? 'USER' : 'ADMIN',
      'email': this.f.email.value,
      'firstName': this.f.firstName.value,
      'lastName':  this.f.lastName.value,
      'password': CryptoJS.SHA512(this.f.password.value).toString()
    }
    this.userService.createUsers(data).then((res) => {
      
      this.dialog.success('Create Success!');
    }).catch((e) => {
      this.dialog.notification('Create User Error!', e.resultMsg || 'Unknown Error', () => { });
    }).finally(()=>{
      this.loading.hide();
    })
  }

  checkFormValidate (field: string | number, status? :any) {
    const infoItem = this.signInfoList.find(item => item.field === field)
    infoItem.status = this.signUpForm.controls[field].status !== 'VALID'
  };

  pswStatus = false;
  //
  checkPswValidate() {
    this.pswStatus = this.signUpForm.controls['password'].status !== 'VALID'

  }

  get f() {
    return this.signUpForm.controls;
  }

  toSignIn() {
    this.loginService.logoutAlertAndSignIn();
  }

}
